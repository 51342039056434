export default {
  notaremision_data(state, request) {
    state.notaremision_data = request;
  },
  notaremision_search(state, request) {
    state.notaremision_search = request;
  },
  notaremision_pendiente(state, request) {
    state.notaremision_pendiente = request;
  },
  notaremision_reporte(state, request) {
    state.notaremision_reporte = request;
  },
  notaremision_byId(state, request) {
    state.notaremision_byId = request;
  }, 
  notaremision_load(state, request) {
    state.notaremision_load = request;
  }, 
  notaremision_save_load(state, request) {
    state.notaremision_save_load = request;
  }, 
}