const months_arr = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SEP",
  "OCT",
  "NOV",
  "DIC",
];
export const months = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];
export const current_date = () => {
  return new Date().toISOString().substr(0, 10);
};
export const getMonthPosition = (date) => {
  const temp = date.split("-");
  return Number(temp[1]);
};
export const current_day = () => {
  const date = new Date();
  return date.getDate();
};
export const current_time = () => {
  let hour, minute, second;
  const today = new Date();
  today.getHours().toString().length === 1
    ? (hour = "0" + today.getHours())
    : (hour = today.getHours());
  today.getMinutes().toString().length === 1
    ? (minute = "0" + today.getMinutes())
    : (minute = today.getMinutes());
  today.getSeconds().toString().length === 1
    ? (second = "0" + today.getSeconds())
    : (second = today.getSeconds());
  return hour + ":" + minute + ":" + second;
};

export const sum_days = (dias) => {
  let fecha = new Date();
  fecha.setDate(fecha.getDate() + dias);
  return fecha.toISOString().substr(0, 10);
};
export const dmy = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return `${day.trim()}-${month.trim()}-${year.trim()}`;
};
export const dmy_s = (date) => {
  if (!date) return null;
  let fecha = date.replace(" ", "T").split("T")
  const [year, month, day] = fecha[0].split("-")
  const second = fecha[1].slice(0, 8)
  return `${day.trim()}-${month.trim()}-${year.trim()} ${second}`;
};
export const ymd = (date) => {
  if (!date) return null;
  const [day, month, year] = date.split("-");
  return `${year.trim()}-${month.trim()}-${day.trim()}`;
};

export const getYearFromDate = (date) => {
  const [year] = date.split("-");
  return year;
};

export const getDayMonthFromDate = (date) => {
  if (!date) return "";
  const temp = date.split("-");
  return `${temp[2]} ${months_arr[temp[1] - 1]}`;
};

export const getDayMonthYearFromDate = (date) => {
  if (!date) return "";
  const temp = date.split("-");
  return `${temp[2]} ${months_arr[temp[1] - 1]} ${temp[0]}`
};

export const substract_days = (dias) => {
  let fecha = new Date();
  fecha.setDate(fecha.getDate() - dias);
  return fecha.toISOString().substr(0, 10);
};

export const current_first_date = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    .toISOString()
    .substr(0, 10);
};

export const current_last_date = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    .toISOString()
    .substr(0, 10);
};

export const unixMesAno = (data) => {
  let date = new Date(data * 1000);
  let year = date.getFullYear();
  let month = months_arr[date.getMonth()];
  return month + "-" + year;
};

export const unixDia = (data) => {
  let date = new Date(data * 1000);
  let day = date.getDate();
  return day;
};

export const unixDiaMesAno = (data) => {
  const date = new Date(data + "T00:00:00")
  const day_arr = [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIERCOLES",
    "JUEVES",
    "VIERNES",
    "SABADO",
  ];
  //let date = new Date(data * 1000);
  let dayString = day_arr[date.getDay()];
  let dayNumber = date.getDate();
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  return `${dayString} ${dayNumber} de ${month} de ${year}`;
}

export const unixDiaMes = (data) => {
  const day_arr = [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIERCOLES",
    "JUEVES",
    "VIERNES",
    "SABADO",
  ];
  let date = new Date(data * 1000);
  let dayString = day_arr[date.getDay()];
  let dayNumber = date.getDate();
  let month = months_arr[date.getMonth()];
  return `${dayString} ${dayNumber} ${month}`;
};

export const unixHora = (data) => {
  let date = new Date(data * 1000);
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  let str_second = new String(second);
  if (str_second.length == 1) second = "0" + second;

  let str_minute = new String(minute);
  if (str_minute.length == 1) minute = "0" + minute;

  let str_hour = new String(hour);
  if (str_hour.length == 1) hour = "0" + hour;

  return hour + ":" + minute + ":" + second;
};


export const horasFormat = (data) => {
  const temp = (data) * 60
  const hours = Math.floor(temp / 60);
  const min = Math.floor(temp % 60);
  return `${hours} h ${min} m`
}