export const AGENDA = 'Agenda'
export const ANALISIS = 'Analisis'
export const CAJAS_Y_BANCOS = 'Caja'
export const CAUSA_NO_INSEMINACION = 'Causa No Inseminacion'
export const CELO = 'Celo'
export const COTIZACION = 'Cotizacion'
export const COSTO_NACIMIENTO = 'Costo Nacimiento'
export const DIARIO_DE_CAJA = 'Diario de Caja'
export const INSEMINACION = 'Inseminacion'
export const ITEM = 'Item'
export const LOTE = 'Lote'
export const MEDICACION = 'Medicacion'
export const MEDICACION_MOTIVO = 'Medicacion Motivo'
export const VETERINARIO = 'Veterinario'
export const TIPO_ANALISIS = 'Tipo Analisis'
export const ABORTO = 'Aborto'
export const AJUSTE_PRODUCCION = 'Ajuste Produccion'
export const AJUSTE_STOCK = 'Ajuste Stock'
export const ANIMAL = 'Animal'
export const ROL = 'Rol'
export const APERTURA_CAJA = 'Apertura Caja'
export const BANCO = 'Banco'
export const CAJA = 'Caja'
export const CATEGORIA_ITEM = 'Categoria Item'
export const CAUSA_ABORTO = 'Causa Aborto'
export const CAUSA_RECHAZO = 'Causa Rechazo'
export const INFORME_COMPRA_VENTA = 'Informe Compra/Venta'
export const COMPRA = 'Compra'
export const CONCEPTO = 'Concepto'
export const CONDUCTOR = 'Conductor'
export const CONFIG_IMPRESION = 'Configuracion de Impresion'
export const INFORME_CAJA_CUENTA = 'Informe de Caja y Cuenta'
export const CUENTA = 'Cuenta'
export const CUENTA_BANCO = 'Cuenta Banco'
export const CUENTA_PAGAR = 'Cuenta Pagar'
export const COBRO_CUENTA = 'Cobro Cuenta'
export const EXTRACTO_PROVEEDOR = 'Extracto de Proveedor'
export const EXTRACTO_CLIENTE = 'Extracto de Cliente'
export const DEPOSITO = 'Deposito'
export const DIAGNOSTICO_UTERO = 'Diagnostico Utero'
export const EMPRESA = 'Empresa'
export const ENFERMEDAD = 'Enfermedad'
export const RUBRO = 'Rubro'
export const ESTABLECIMIENTO = 'Establecimiento'
export const DIVISION = 'Division'
export const ESTADO_CRIA = 'Estado Cria'
export const ESTADO_PEDIDO = 'Estado Pedido'
export const FORMA_PAGO = 'Forma Pago'
export const HISTORICO_CALIDAD = 'Historico Calidad'
export const PERFIL_FINANCIAMIENTO = 'Perfil Financiamiento'
export const CUENTA_DIARIO = 'Cuenta Diario'
export const MARCA = 'Marca'
export const REFERENCIA = 'Referencia'
export const MOTIVO_AJUSTE = 'Motivo Ajuste'
export const MOTIVO_MUERTE = 'Motivo Muerte'
export const MOTIVO_TRASLADO = 'Motivo Traslado'
export const EMITIR_CHEQUE = 'Emitir Cheque'
export const MUERTE = 'Muerte'
export const TARJETA_CREDITO = 'Tarjeta de Credito'
export const NOTA_CREDITO_VENTA = 'Nota Credito Venta'
export const NOTA_REMISION = 'Nota Remision'
export const PARTO = 'Parto'
export const PROCEDENCIA = 'Procedencia'
export const PRODUCCION_LECHE = 'Produccion Leche'
export const PROVEEDOR = 'Proveedores'
export const PUNTO_EXPEDICION = 'Punto Expedicion'
export const RACION = 'Racion'
export const RAZA = 'Raza'
export const RECHAZO = 'Rechazo'
export const RESPONSABLE = 'Responsable'
export const SECADO = 'Secado'
export const PALPACION = 'Palpacion'
export const SUCURSAL = 'Sucursal'
export const TIMBRADO = 'Timbrado'
export const TIPO_AJUSTE = 'Tipo Ajuste'
export const TIPO_CUENTA_BANCO = 'Tipo Cuenta Banco'
export const TIPO_ENFERMEDAD = 'Tipo Enfermedad'
export const TIPO_PARTO = 'Tipo Parto'
export const TIPO_RESPONSABLE = 'Tipo Responsable'
export const TRANSFERENCIA_BANCO_BANCO = 'Transferencia de Banco a Banco'
export const TRANSFERENCIA_CAJA_BANCO = 'Transferencia de Caja a Banco'
export const TRANSFERENCIA_CAJA_CAJA = 'Transferencia de Caja a Caja'
export const TRANSPORTADORA = 'Transportadora'
export const TURNO_ORDENO = 'Turno Ordeno'
export const VEHICULO = 'Vehiculo'
export const VENTA = 'Venta'
export const VIA_APLICACION = 'Via Aplicacion'
export const STOCK = 'Stock'
export const COMPRA_ORDEN = 'Compra Orden'
export const COMPRA_PEDIDO = 'Compra Pedido'
export const CLIENTE = 'Cliente'
export const USUARIO = 'Usuario'
export const PAGO_CUENTA = 'Pago Cuenta'
export const EGRESO = 'Egreso'
export const PUNTO_VENTA = 'Punto Venta'
export const ZONAS_CULTIVO = 'Zonas de Cultivo'
export const ZAFRA = 'Zafra'
export const RECETARIO = 'Recetario'
export const PROPIEDAD_QUIMICA = 'Propiedad Quimica'
export const ROL_USUARIO = 'Rol de Usuario'
export const REPORTE_AGRICULTURA = 'Reportes Agricultura'
export const AJUSTE_CAJA = 'Ajuste de Caja'
export const AJUSTE_CUENTA_BANCO = 'Ajuste de Cuenta Banco'
export const TRANSFERENCIA_BANCO_CAJA = 'Transferencia de Banco a Caja'
export const INSUMOS = 'Insumos'
export const VENTA_GRANOS = 'Venta de Granos'
export const NOTA_CREDITO_COMPRA = 'Nota de Credito Compra'
export const REPORTE_COMERCIAL = 'Reporte Comercial'
export const FUNCIONARIO = 'Funcionario'
export const SECTOR = 'Sector'
export const NIVEL = 'Nivel'
export const CONCEPTO_SALARIAL = 'Concepto Salarial'
export const ESTADO_ASISTENCIA = 'Estado Asistencia'
export const RELOJ_BIOMETRICO = 'Reloj Biometrico'
export const ASISTENCIA = 'Asistencia'
export const PAGO_SALARIO = 'Pago a Funcionario'
export const JUSTIFICACIONES = 'Justificaciones'
export const CONFIG_RRHH = 'Configuracion Capital Humano'
export const REPORTE_RRHH = 'Reporte Recurso Humano'
export const CONTRATO = 'Contrato'
export const POLIGONO_MARCACION = 'Poligono de Marcacion'
export const OBJETIVO = 'Objetivo'
export const REGISTRO_PLAGA = 'Registro de Plagas'
export const TERMO_SEMEN = 'Termo Semen'
export const VENTA_GANADO = 'Venta Ganado'
export const CANJE_GRANO = 'Canje de Granos'
export const PREPARTO = 'Preparto'
export const TIPO_GIMNASIO = 'Tipo Gimnasio'
export const PLANILLA_GIMNASIO = 'Planilla Gimnasio'
export const RUTINA_GIMNASIO = 'Rutina Gimnasio'
export const ASISTENCIA_GIMNASIO = 'Asistencia Gimnasio'
export const TRANSFERENCIA = 'Transferencia Stock'
export const FABRICANTE = 'Fabricante'
export const TIPO_MAQUINARIA = 'Tipo Maquinaria'
export const MAQUINARIA = 'Maquinaria'
export const TIPO_MANTENIMIENTO_MAQUINARIA = 'Tipo Mantenimiento Maquinaria'
export const MANTENIMIENTO_MAQUINARIA = 'Mantenimiento Maquinaria'
export const ASIGNACION_MAQUINARIA = 'Asignacion Maquinaria'
export const TIPO_GASTO_MAQUINARIA = 'Tipo Gasto Maquinaria'
export const GASTO_MAQUINARIA = 'Gasto Maquinaria'
export const TRANSPORTE_MAQUINARIA = 'Transporte Maquinaria'
export const SALARIO_MAQUINARIA = 'Salario Maquinaria'
export const CONFIG_MAQUINARIA = 'Configuracion Maquinaria'
export const FINANZA = 'Finanza'
export const ALIMENTACION = 'Alimentacion'