<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="Vget_motivotraslado_load"
    >Desea eliminar el estado <strong>{{ props.descmotivotraslado }}</strong></c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("motivotraslado", ["Vget_motivotraslado_load"])
    },
    watch: {
        $route() {
            return this.close()
        }
    },
    methods: {
        ...mapActions("motivotraslado", ["Vact_motivotraslado_delete", "Vact_motivotraslado_data"]),
        async save() {
            const id = this.props.idmotivotraslado
            const response = await this.Vact_motivotraslado_delete(id)
            if (!response.success) return null
            this.close()
        },
        close() {
            this.$emit("datos", null)
            this.$emit("input", false)
            this.Vact_motivotraslado_data()
        }
    }
}
</script>