import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { current_date } from "@/services/util/date.service"
import { horasFormat } from "@/services/util/date.service";

export const excelEmpleadoAsistencias = (datos, title, detcontrato) => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("HOJA 1");
    worksheet.addRow(title)
    worksheet.getRow(1).font = { bold: true }
    worksheet.addRow(['Normal', horasFormat(detcontrato.asistencia - detcontrato.total_horaextra)])
    worksheet.addRow(['Extra', detcontrato.total_horaextra_texto])
    worksheet.addRow(['Total', detcontrato.asistencia_texto])
    worksheet.addRow(['Dias trabajados', detcontrato.dias_trabajo])
    worksheet.addRow(['Ausencias', detcontrato.ausencias])
    worksheet.addRow(['Fecha', 'Entrada', 'Salida', 'Entrada', 'Salida', 'Cantidad'])
    let tableData = []
    datos.forEach(dia => {
        let detalle = []
        let horas_detalle = 0
        dia.data.forEach((registro, index) => {
            if (index === 0) {
                detalle = [
                    dia.name,
                    registro.inicio_hora_sistema,
                    registro.fin_hora,
                    null,
                    null,
                ]
            } else {
                detalle[3] = registro.inicio_hora_sistema
                detalle[4] = registro.fin_hora
            }
            horas_detalle += registro.total != undefined ? registro.total : 0
        });
        detalle.push(horasFormat(horas_detalle))
        tableData.push(detalle)
    })
    worksheet.addRows(tableData)
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, `${title || 'EXCEL'} ${current_date()}.xlsx`);
    });
}

export const exportEmpleadoAsistencias = (datos, title, detcontrato) => {
    var doc = new jsPDF();
    doc.setFontSize(14);
    doc.text(title, doc.internal.pageSize.width / 2, 20, { align: 'center' });
    doc.setFontSize(12);
    let tableData = [];
    datos.forEach(dia => {
        let detalle = []
        let horas_detalle = 0
        dia.data.forEach((registro, index) => {
            if (index === 0) {
                detalle = [
                    dia.name,
                    registro.inicio_hora_sistema,
                    registro.fin_hora,
                    null,
                    null,
                ]
            } else {
                detalle[3] = registro.inicio_hora_sistema
                detalle[4] = registro.fin_hora
            }
            horas_detalle += registro.total != undefined ? registro.total : 0
        });
        detalle.push(horasFormat(horas_detalle))
        tableData.push(detalle)
    });
    doc.text(`Normal: ${horasFormat(detcontrato.asistencia - detcontrato.total_horaextra)}`, 20, 30)
    doc.text(`Extra: ${detcontrato.total_horaextra_texto}`, 80, 30)
    doc.text(`Total: ${detcontrato.asistencia_texto}`, 140, 30)
    doc.text(`Dias trabajados: ${detcontrato.dias_trabajo}`, 20, 40)
    doc.text(`Ausencias: ${detcontrato.ausencias}`, 80, 40)
    doc.autoTable({
        theme: 'grid',
        head: [['Fecha', 'Entrada', 'Salida', 'Entrada', 'Salida', 'Cantidad']],
        body: tableData,
        startY: 45,
    });
    doc.save(title + ' ' + current_date() + '.pdf');
}