
export default {

  Vget_motivotraslado_data: (state) => {
    return state.motivotraslado_data
  },
  Vget_motivotraslado_show: (state) => {
    return state.motivotraslado_show
  },
  Vget_motivotraslado_load: (state) => {
    return state.motivotraslado_load
  },
}
