<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="Vget_pedidocompra_load">
        Desea eliminar la nota?
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mensaje } from "@/services/notify.service";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("pedidocompra", ["Vget_pedidocompra_load"]),
    },
    watch: {
        $route() {
            return this.close()
        }
    },
    methods: {
        ...mapActions("pedidocompra", ["Vact_pedidocompra_del", "Vact_pedidocompra_data"]),
        async save() {
            const id = this.props.idcomprapedido
            const response = await this.Vact_pedidocompra_del(id)
            if (response.estado == true) {
                this.$notify(response.info);
                this.close()
            } else {
                response.info.map((x) => {
                    this.$notify(mensaje.error(x));
                })
            }
        },
        close() {
            this.$emit("datos", null)
            this.$emit("input", false)
            this.Vact_pedidocompra_data({ limit: 100, desc: null })
        }
    }
}
</script>