export default {

  compra_data(state, request) {
    state.compra_data = request;
  },
  compra_search(state, request) {
    state.compra_search = request;
  },
  compra_data_id(state, request) {
    state.compra_data_id = request;
  },
  compra_load(state, request) {
    state.compra_load = request;
  },
  compra_save_load(state, request) {
    state.compra_save_load = request;
  }
}