export default {
  motivotraslado_data(state, request) {
    state.motivotraslado_data = request;
  }, 
  motivotraslado_load(state, request) {
    state.motivotraslado_load = request;
  },   
  motivotraslado_show(state, request) {
    state.motivotraslado_show = request;
  },
}