<template>
    <div>
        <c-alert-permission v-model="permission"></c-alert-permission>
        <v-form ref="form" v-if="permission.can_view">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" sm="3">
                        <v-autocomplete :filter="customFilter" :search-input.sync="searchitem"
                            v-bind:items="Vget_item_agrostock" item-text="iditem.descitem" item-value="iditem.iditem"
                            :rules="validar" autocomplete="off" hide-details :loading="Vget_item_load"
                            label="Seleccione el Producto" ref="input1" id="input1" @keyup.enter="next(1, data.item)"
                            v-model="data.item"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete :items="Vget_campana_data" item-text="descripcion" return-object
                            :rules="validar" autocomplete="off" hide-details :loading="Vget_campana_load"
                            label="Seleccione la Campaña" ref="input2" id="input2" @keyup.enter="next(2, data.zafra)"
                            v-model="data.zafra"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete v-if="data.zafra" :items="data.zafra.campanadet" item-text="idlote_agri.campo" item-value="idlote_agri.idlote_agri" multiple
                            :rules="validar" autocomplete="off" hide-details label="Seleccione el Lote" id="input5"
                            @keyup.enter="next(5, data.idtiposeleccionado)" v-model="data.lote"></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-4" text fab small color="primary" id="input3" ref="input3" outlined
                        @click="descargarDatos()">
                        <v-icon>cloud_download</v-icon>
                    </v-btn>
                    <v-btn class="mt-4 ml-1" text fab small color="red" outlined @click="generarPdf()">
                        <v-icon>get_app</v-icon>
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-form>
        <v-card-text v-if="response.zafra != ''">
            <v-row>
                <v-col cols="12" sm="4" class="font-weight-bold">Campaña: {{ response.zafra }}</v-col>
                <v-col cols="12" sm="4" class="font-weight-bold">Producto: {{ response.item }}</v-col>
                <v-col cols="12" sm="4" class="font-weight-bold">Composición: {{ response.composicion }}</v-col>
                <v-col cols="12" sm="4" class="font-weight-bold">Fecha Consulta: {{ response.fecha_consulta }}</v-col>
                <v-col cols="12" sm="4" class="font-weight-bold">Usado en Zafra: {{ toCurrency(response.usado) }} {{
                    response.unidad }}</v-col>
                <v-col cols="12" sm="4" class="font-weight-bold">Inversion en Zafra: {{ toCurrency(response.inversion)
                    }}</v-col>
            </v-row>

            <v-divider class="mb-2 mt-2"></v-divider>

            <div v-for="item in response.detalle" :key="item.id">
                <v-row>
                    <v-col cols="12" sm="3" class="font-weight-bold">Lote: {{ item.lote }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">Hectarea: {{ toCurrency(item.hectarea) }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">Usado en Lote: {{ toCurrency(item.usado) }} {{
                        item.unidad }}</v-col>
                    <v-col cols="12" sm="3" class="font-weight-bold">Inversion en Lote: {{ toCurrency(item.inversion)
                        }}</v-col>
                </v-row>
                <v-simple-table v-slot:default class="primary mb-2">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Hectarea</th>
                            <th>Cantidad x h</th>
                            <th>Total</th>
                            <th>Precio</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item2 in item.detalle" :key="item2.id">
                            <td>{{ item2.fecha }}</td>
                            <td>{{ toCurrency(item2.ha) }}</td>
                            <td>{{ toCurrency(item2.cantidad) }} {{ item2.unidad }}</td>
                            <td>{{ toCurrency(item2.total_uso) }}</td>
                            <td>{{ toCurrency(item2.precio) }}</td>
                            <td>{{ toCurrency(item2.subtotal) }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </v-card-text>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
import { exportUltimaAplicacion } from '../Export';
import { currency } from "@/services/util/number.service";
export default {
    data() {
        return {
            response: {
                item: "",
                zafra: "",
                composicion: "",
                fecha_consulta: "",
                detalle: []
            },
            searchitem: "",
            validar: [(v) => !!v || "Seleccione un campo"],
            data: {
                item: null,
                zafra: null,
                lote: []
            },
            permission: {
                can_view: false,
            },
            awaitingSearch: false,
        }
    },
    created() {
        this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[
            PUEDE_GENERAR_REPORTE
        ]
        this.Vact_campana_data()
    },
    mounted() {
        this.Vact_campo_data('yes');
        setTimeout(() => this.next(0, "-"), 20)
    },
    watch: {
        searchitem(val) {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.obtain_item(val)
                    this.awaitingSearch = false
                }, 1000)
            }
            this.awaitingSearch = true
        }
    },
    computed: {
        ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
        ...mapGetters("item", ["Vget_item_agrostock", "Vget_item_load"]),
        ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"])
    },
    methods: {
        ...mapActions("campo", ["Vact_campo_data"]),
        ...mapActions("item", ["Vact_item_agrostock"]),
        ...mapActions("campana", ["Vact_campana_data"]),
        toCurrency(value) {
            if (value) return currency(Number(value));
            return "0";
        },
        obtain_item(val) {
            if (this.searchitem != null && this.searchitem.length > 3) {
                this.Vact_item_agrostock({
                    search: val
                })
            }
        },
        next(id, data) {
            if (data != "")
                try {
                    this.$refs[`input${id + 1}`].focus()
                } catch (error) {
                    this.$refs[`input${id + 1}`].$el.focus()
                }
        },
        generarPdf() {
            exportUltimaAplicacion("Ultima Aplicacion", this.response)
        },
        async descargarDatos() {
            try {
                let params = ""
                if (this.data.lote.length != 0) {
                    for (let i = 0; i < this.data.lote.length; i++) {
                        if (i === 0) {
                            params += `?lote=${this.data.lote[i]}`
                        } else {
                            params += `+${this.data.lote[i]}`
                        }
                    }
                }
                this.response = await db.get(`apireportetodasalicaciones/${this.data.item}/${this.data.zafra.idcampana}/${params}`)
                console.log(this.reponse)
            } catch (error) {
                console.log(error)
                this.$notify(mensaje.error(error.message));
            }
        },
        customFilter(item, queryText) {
            const textOne = item.iditem.descitem.toLowerCase();
            const textTwo = item.iditem.iditem.toString();
            const searchText = queryText.toLowerCase();
            return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            );
        },
        search() {
            this.Vget_item_agrostock().forEach((x) => {
                if (x.iditem.iditem === this.searchItemId) {
                    return
                }
            });
        }
    }
}
</script>