
export default {
  Vget_estadopedido_data: (state) => {
    return state.estadopedido_data
  },
  Vget_estadopedido_byId: (state) => {
    return state.estadopedido_byId
  },
  Vget_estadopedido_load: (state) => {
    return state.estadopedido_load
  },
  Vget_estadopedido_save_load: (state) => {
    return state.estadopedido_save_load
  },
}
