export default {
  notaremision_data: [],
  notaremision_search: [],
  notaremision_pendiente: [],
  notaremision_reporte: [],
  notaremision_byId: null,
  notaremision_load: false,
  notaremision_save_load: false
}


