import { db } from "@/services/db.service";

export default {
    async Vact_pedidocompra_data({ commit }, { desc = null, limit = null, contacto = null }) {
        commit('pedidocompra_load', true);
        try {
            let data = ''
            if (contacto) {
                data = await db.get(`/apipedidocompra/?contacto=${contacto}`)
                commit('pedidocompra_search', data)
            } else if (desc) {
                data = await db.get(`/apipedidocompra/?desc=${desc}`);
                commit('pedidocompra_data', data)
            } else {
                data = await db.get(`/apipedidocompra/?limit=${limit}`);
                commit('pedidocompra_data', data)
            }
            
        } catch (e) {
            commit('pedidocompra_save_load', false);
            return e
        }
        commit('pedidocompra_load', false);
    },
    async Vact_pedidocompra_search({ commit }, { contacto }){
        commit('pedidocompra_load', true);
        try {
            const data = await db.get(`/apipedidocompra/?contacto=${contacto}`)
            commit('pedidocompra_search', data)
        } catch (e) {
            commit('pedidocompra_load', false);
            return e
        }
        commit('pedidocompra_load', false);
    },
    async Vact_pedidocompra_byId({ commit }, id) {
        commit('pedidocompra_load', true);
        commit('pedidocompra_byId', null);
        try {
            const data = await db.get('/apipedidocompra/' + id + '/');
            commit('pedidocompra_byId', data);
            commit('pedidocompra_load', false);
        } catch (e) {
            commit('pedidocompra_load', false);
            return e
        }
    },
    async Vact_pedidocompra_del({ dispatch }, id) {
        try {
            const data = await db.delete('/apipedidocompra/' + id + '/');
            dispatch('Vact_pedidocompra_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_pedidocompra_save({ commit }, data) {
        commit('pedidocompra_save_load', true);
        try {
            const resp = await db.save('/apipedidocompra/', data);
            commit('pedidocompra_save_load', false);
            return resp;
        } catch (e) {
            commit('pedidocompra_save_load', false);
            return e
        }
    },
    async Vact_pedidocompra_update({ commit }, { id, data }) {
        commit('pedidocompra_save_load', true);
        try {
            const resp = await db.update('/apipedidocompra/' + id + '/', data);
            commit('pedidocompra_save_load', false);
            return resp;
        } catch (e) {
            commit('pedidocompra_save_load', false);
            return e
        }
    }
}
