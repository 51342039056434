<template>
  <v-dialog :value="value" max-width="800" persistent>
    <v-card>
      <v-toolbar flat>

        <div class="text-h6">
          Resumen del pago de
          <span class="font-weight-black">{{
            props.idcontacto.idcontacto.desccontacto
            }}</span>
        </div>
        <v-spacer></v-spacer>
        <c-text-date class="mt-6" label="Fecha de Pago" :readonly="readonly" v-model="data.fecha"></c-text-date>
      </v-toolbar>

      <div class="subtitle-1 mx-2">
        <p>
          Periodo de pago:
          <span class="font-weight-black">
            {{ formatDate(props.periodo_inicio) }} /
            {{ formatDate(props.periodo_fin) }}
          </span>
          -
          Horas trabajadas:
          <span class="font-weight-black">
            {{ horasFormat2(props.trabajadas) }}
          </span>
        </p>
        <p>
          <span>Debitado de la cuenta</span>
          <span class="font-weight-black">
            {{ props.idcuentabanco.desccuentabanco }}
          </span>
          <span>del banco: </span>
          <span class="font-weight-black">{{
            props.idcuentabanco.idbanco.descbanco
          }}, </span>
          <span>con el tipo de moneda </span>
          <span class="font-weight-black">
            {{ props.idmoneda.descmoneda }}
          </span>
        </p>
      </div>

      <v-data-table :headers="headers" :items="resumen" :items-per-page="999999" hide-default-footer class="mt-4">
        <template v-slot:[`item.ingreso`]="{ item }">
          <div>
            {{ toCurrency(item.ingreso) }}
          </div>
        </template>
        <template v-slot:[`item.egreso`]="{ item }">
          <div>
            {{ toCurrency(item.egreso) }}
          </div>
        </template>
        <template slot="body.append">
          <tr>
            <th>
              <span class="subtitle-1 font-weight-black customtext--text">TOTAL</span>
            </th>

            <th class="text-right">
              <span class="subtitle-1 font-weight-black customtext--text">
                {{ toCurrency(data.totalIngreso) }}</span>
            </th>
            <th class="text-right">
              <span class="subtitle-1 font-weight-black customtext--text">
                {{ toCurrency(data.totalEgreso) }}</span>
            </th>
          </tr>
          <tr>
            <th class="text-left subtitle-1 font-weight-black customtext--text">
              Neto a Recibir:
            </th>
            <th></th>

            <th class="text-right subtitle-1 font-weight-black customtext--text">
              {{ toCurrency(data.monto) }}
            </th>
          </tr>
        </template>
      </v-data-table>
      <v-textarea class="mx-2" :readonly="readonly" rows="2" label="Comentario"></v-textarea>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" text color="primary" @click="generarReciboPrincipal()"><v-icon>print</v-icon></v-btn>
        <c-btn-crud-done v-if="!readonly" @click="$emit('save')"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isSalarioLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { configRecibo } from "./constants";
import { currency } from "@/services/util/number.service";
import { dmy, current_date } from "@/services/util/date.service";
import { mapGetters, mapActions } from "vuex";
import { generarRecibo } from "./recibo";
import { horasFormat } from "@/services/util/date.service";
import { generarRecibo01 } from "./Formatos/recibo01";
import { generarRecibo02 } from "./Formatos/recibo02";
import { money } from "@/services/moneyconvert";
export default {
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: {
      fecha: current_date(),
      comentario: "",
    },
    headers: [
      { text: "Concepto", value: "descripcion", sortable: false },
      { text: "Ingresos", value: "ingreso", align: "right", sortable: false },
      { text: "Egresos", value: "egreso", align: "right", sortable: false },
    ],
    resumen: [],
  }),
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.props));
    this.obtenerDebeHaber();
    this.fetchConfigRrhh();
  },
  computed: {
    ...mapGetters("salario", ["isSalarioLoading"]),
    ...mapGetters("empresa", ["Vget_empresa_active"]),
    ...mapGetters("configrrhh", ["getConfigRrhh"]),
  },
  watch: {
    getConfigRrhh(val) {
      return val
    }
  },
  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh"]),
    generarReciboPrincipal() {
      let recibo = this.getConfigRrhh
      console.log(recibo)
      switch (recibo.recibo.id) {
        case 1:
          this.generarRecibo()
          break;
        case 2:
          this.generarRecibo01()
          break;
        case 3:
          this.generarRecibo02()
          break
        default:
          break;
      }
    },
    generarRecibo() {
      generarRecibo(this.props, configRecibo, "Salario");
    },
    generarRecibo01() {
      let detalle = []
      let ingreso = 0
      let egreso = this.data.monto
      this.data.detalle.forEach(element => {
        detalle.push([
          element.idconcepto_salario.idconcepto_salario,
          element.idconcepto_salario.descripcion,
          "",
          !element.idconcepto_salario.positivo_negativo ? ((egreso = egreso + element.monto), this.toCurrency(element.monto)) : 0,
          element.idconcepto_salario.positivo_negativo ? ((ingreso = ingreso + element.monto), this.toCurrency(element.monto)) : 0,
        ])
      });
      detalle.push(
        [
          2,
          "Pagado en fecha",
          "",
          this.toCurrency(this.data.monto),
          0,
        ]
      )
      detalle.push(
        [
          "",
          "",
          "",
          this.toCurrency(egreso),
          this.toCurrency(ingreso),
        ]
      )
      generarRecibo01({
        title: {
          empresa: this.Vget_empresa_active[0].idempresa.descempresa,
          direccion: this.Vget_empresa_active[0].idtambo.diretambo,
          telefono: this.Vget_empresa_active[0].idtambo.teletambo,
          transaccion: this.data.idpago_salario,
          fecha: this.formatDate(this.data.fecha),
          funcionario: this.data.idcontacto,
          operacion: "Salario",
          periodo: `${this.formatDate(this.data.periodo_inicio)} a ${this.formatDate(this.data.periodo_fin)}`,
        },
        detalle: detalle,
        monto: this.data.monto,
        moneda: this.data.idmoneda,
        diferencia: `Saldo: ${this.toCurrency(ingreso - egreso)}`
      })
    },
    generarRecibo02() {
      console.log(this.data)
      let detalle = []
      let ingreso = 0
      let egreso = 0
      this.data.detalle.forEach(element => {
        detalle.push([
          element.idconcepto_salario.idconcepto_salario,
          element.idconcepto_salario.descripcion,
          "",
          !element.idconcepto_salario.positivo_negativo ? ((egreso = egreso + element.monto), this.toCurrency(element.monto)) : 0,
          element.idconcepto_salario.positivo_negativo ? ((ingreso = ingreso + element.monto), this.toCurrency(element.monto)) : 0,
        ])
      });
      for (let index = 0; index < 8; index++) {
        if (detalle.length < index) {
          detalle.push(["", "", "", "", ""])
        }
        
      }
      detalle.push(
        [
          `Total en ${this.data.idmoneda.plural}: ${money.letter(this.data.monto)}`,
          "",
          "",
          this.toCurrency(egreso),
          this.toCurrency(ingreso),
        ]
      )
      detalle.push(
        [
          "",
          "",
          "",
          "Saldo",
          this.toCurrency(this.data.monto)
        ]
      )
      generarRecibo02({
        title: {
          empresa: this.Vget_empresa_active[0].idempresa.descempresa,
          sucursal: this.Vget_empresa_active[0].idtambo.nombtambo,
          direccion: this.Vget_empresa_active[0].idtambo.diretambo,
          telefono: this.Vget_empresa_active[0].idtambo.teletambo,
          transaccion: this.data.idpago_salario,
          fecha: this.formatDate(this.data.fecha),
          fecha_: this.data.fecha,
          funcionario: this.data.idcontacto,
          operacion: "Salario",
          periodo: `${this.formatDate(this.data.periodo_inicio)} a ${this.formatDate(this.data.periodo_fin)}`,
        },
        detalle: detalle,
        monto: this.data.monto,
        moneda: this.data.idmoneda,
        diferencia: this.toCurrency(ingreso - egreso)
      })
    },
    cancelar() {
      this.$emit("input", false);
    },
    toCurrency(value) {
      if (value) return currency(Number(value));
      return "0";
    },
    formatDate(value) {
      return dmy(value);
    },
    obtenerDebeHaber() {
      let ingreso = 0;
      let egreso = 0;
      this.resumen = this.props.detalle.map((x) => {
        return {
          descripcion: x.idconcepto_salario.descripcion,
          ingreso: x.idconcepto_salario.positivo_negativo
            ? ((ingreso = ingreso + x.monto), x.monto)
            : "",
          egreso: !x.idconcepto_salario.positivo_negativo
            ? ((egreso = egreso + x.monto), x.monto)
            : "",
        };
      });
    },
    horasFormat2(data) {
      return horasFormat(data)
    }
  },
};
</script>

<style></style>
