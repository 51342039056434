export default [
    {
        path: '/nacionalidad',
        name: 'Nacionalidad',
        component: () => import( /* webpackChunkName: "Nacionalidad" */ '@/views/Mantenimientos/Nacionalidad/Nacionalidad.vue'),
      },
      {
        path: '/ciudad',
        name: 'Ciudad',
        component: () => import( /* webpackChunkName: "Ciudad" */ '@/views/Mantenimientos/Ciudad/Ciudad.vue'),
      },
      {
        path: '/tambo',
        name: 'Tambo',
        component: () => import( /* webpackChunkName: "Tambo" */ '@/views/Mantenimientos/tambo/List.vue'),
      },
      {
        path: '/empresa',
        name: 'Empresa',
        component: () => import( /* webpackChunkName: "Tambo" */ '@/views/Mantenimientos/empresa/List.vue'),
      },
      {
        path: '/vehiculo',
        name: 'Vehiculo',
        component: () => import( /* webpackChunkName: "Vehiculo" */ '@/views/Mantenimientos/Vehiculo/Vehiculo.vue'),
      }
]


