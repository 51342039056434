<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="Vget_notaremision_load">
        Desea eliminar la nota?
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("notaremision", ["Vget_notaremision_load"]),
    },
    watch: {
        $route() {
            return this.close()
        }
    },
    methods: {
        ...mapActions("notaremision", ["Vact_notaremision_del", "Vact_notaremision_data"]),
        async save() {
            const id = this.props.idnotaremision
            const response = await this.Vact_notaremision_del(id)
            if (!response.success) return null
            this.close()
        },
        close() {
            this.$emit("datos", null)
            this.$emit("input", false)
            this.Vact_notaremision_data({ limit: 100, desc: null })
        }
    }
}
</script>