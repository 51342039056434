<template>
    <div>
        <div v-if="crud.add">
            <Crud v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <div v-if="crud.view">
            <Crud readonly v-model="crud.view" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <v-card>
            <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta
                ventana</v-alert>
            <v-toolbar flat dense>
                <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                <widgets-Favorite ruta="/notaremision" formulario="Nota Remision" />
                <v-divider class="mx=2" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="mr-1" v-model="search" append-icon="search" label="Busqueda" single-line
                    hide-details v-if="permission.can_view"></v-text-field>
                <v-toolbar-items>
                    <c-btn-table-download class="error mr-2" @click="generarPdf()"
                        v-if="permission.can_view"></c-btn-table-download>
                    <c-btn-table-reload class="mr-2" @click="Vact_notaremision_data({ limit: 100, desc: null })"
                        v-if="permission.can_view"></c-btn-table-reload>
                    <c-btn-table-add @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table :loading="Vget_notaremision_load" :headers="headers" :items="Vget_notaremision_data"
                :search="search" class="elevation-1" v-if="permission.can_view">
                <template v-slot:no-data>
                    <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
                </template>
                <template v-slot:item.accion="props">
                    <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
                    <c-btn-table-edit v-if="permission.can_update" @click="mupdate(props.item)"
                        :disabled="props.item.anulventa"></c-btn-table-edit>
                    <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"
                        :disabled="props.item.anulventa"></c-btn-table-delete>
                </template>
            </v-data-table>

            <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
        </v-card>
    </div>
</template>
<script>
import Crud from "./Crud";
import Delete from "./Delete";
import { mapActions, mapGetters } from 'vuex';
import { getPermission } from '@/services/storage/permissionStorage.service';
import { mensaje } from '@/services/notify.service';
import { NOTA_REMISION } from '@/constants/forms';
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR
} from '@/constants/permissions';
export default {
    components: {
        Crud,
        Delete,
    },
    data() {
        return {
            search: "",
            titulo: "Nota Remision Compra",
            headers: [
                { text: "Fecha", align: "left", value: "fecha" },
                { text: "Proveedor", align: "left", value: "idcontacto.desccontacto" },
                { text: "Timbrado", align: "left", value: "timbrado" },
                { text: "Nro Nota", align: "left", value: "nronotaremision" },
                { text: "Conductor", align: "left", value: "idconductor.desccontacto" },
                { text: "Transportadora", align: "left", value: "idtransportadora.desccontacto" },
                { text: "", align: "right", value: "accion", sortable: false }
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
            crud: {
                add: false,
                view: false,
                delete: false,
                datos: null
            }
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        const permission = getPermission(NOTA_REMISION).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        }
        this.Vact_notaremision_data({ limit: 100, desc: null })
    },
    computed: {
        ...mapGetters("notaremision", ["Vget_notaremision_data", "Vget_notaremision_load"]),
        permission_alert() {
            return mensaje.permission(this.permission)
        }
    },
    watch: {
        search() {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.busqueda()
                    this.awaitingSearch = false
                }, 1500)
            }
            this.awaitingSearch = true
        }
    },
    methods: {
        ...mapActions("notaremision", ["Vact_notaremision_data", "Vact_notaremision_del"]),
        async busqueda() {
            if (this.search.length >= 3 && this.search != null) {
                this.Vact_notaremision_data({ limit: null, desc: this.search })
            }
        },
        obtener(val) {
            console.log(val)
        },
        rowselect(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.view = true
        },
        meliminar(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.delete = true
        },
        mupdate(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.add = true
        }
    }
}
</script>