<template>
    <div>
        <v-progress-linear :active="Vget_pedidocompra_load"></v-progress-linear>
        <v-list subheader two-line>
            <v-subheader inset>Elija un pedido</v-subheader>
            <v-list-item v-for="(i, index) of Vget_pedidocompra_search" :key="index">
                <v-list-item-content>
                    <v-list-item-title v-text="i.idcontacto.desccontacto"></v-list-item-title>
                    <v-list-item-subtitle v-text="i.numpedido"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="$emit('selectedPedido', i)">
                        <v-icon color="info">arrow_forward_ios</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        idproveedor: {
            default: null
        },
    },
    computed: {
        ...mapGetters("pedidocompra", ["Vget_pedidocompra_search", "Vget_pedidocompra_load"]),
    },
    created() {
        this.busqueda(this.idproveedor)
    },
    watch: {
        idproveedor(val) {
            this.busqueda(val)
        }
    },
    methods: {
        ...mapActions("pedidocompra", ["Vact_pedidocompra_search"]), 
        async busqueda(val) {
            try{
                if (val.idcontacto != '') {
                    this.Vact_pedidocompra_search({ contacto: val.idcontacto })
                } else {
                    this.Vact_pedidocompra_search({ contacto: 0 })
                }
            } catch (e) {
                console.log(e)
                this.Vact_pedidocompra_search({ contacto: 0 })
            }
        }
    },
}
</script>