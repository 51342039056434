<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-divider></v-divider>
      <v-btn v-if="permission.can_add || permission.can_update" :disabled="isEditable" fab text small
        color="deep-purple accent-4" class="ml-2" outlined @click="isEditable = !isEditable">
        <v-icon>edit</v-icon></v-btn>
    </v-toolbar>
    <v-form ref="form">
      <v-card-actions>
        <span class="title ml-1">Cuenta Bancaria</span>
      </v-card-actions>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="12">
          <autocomplete-cuentabanco :disabled="!isEditable" :rules="[]" label="Cuentas Bancarias"
            v-model="data.idcuentabanco" />
        </v-col>
      </v-row>
      <span class="title ml-2">Caja</span>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="12">
          <autocomplete-caja :disabled="!isEditable" :rules="[]" label="Cajas"
            v-model="data.configuracioncaja[0].idcaja" />
        </v-col>
      </v-row>
      <span class="title ml-2">Salario</span>
      <v-row dense class="mx-1">
        <v-col cols="12">
          <c-text-currency :disabled="!isEditable" v-model="data.salario_minimo"
            label="Salario Minimo Vigente"></c-text-currency>
        </v-col>
      </v-row>
      <span class="title ml-2">Horas Extras y Bonificaciones</span>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="2">
          <c-text-currency :disabled="!isEditable" v-model="data.hora_nocturna" label="Horas Nocturnas (Porcentaje)"
            suffix="%"></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency :disabled="!isEditable" v-model="data.extra_diurna" suffix="%"
            label="Horas extras Diurna (Porcentaje)"></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency :disabled="!isEditable" v-model="data.extra_nocturna" suffix="%"
            label="Horas extras Nocturna (Porcentaje)"></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency :disabled="!isEditable" v-model="data.domingo_feriado" suffix="%"
            label="Domingos y Feriados (Porcentaje)"></c-text-currency>
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-currency :disabled="!isEditable" v-model="data.bonificacion_familiar" suffix="%"
            label="Bonificacion Familiar (Porcentaje)"></c-text-currency>
        </v-col>
      </v-row>
      <span class="title ml-2">Tolerancias horarias</span>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="6">
          <c-text-currency :disabled="!isEditable" v-model="data.minuto_tolerancia" :rules="[]" placeholder="Ejemplo 5"
            label="Cuantos minutos se tolera para llegadas tardias o salidas tempranas?"></c-text-currency>
        </v-col>
        <v-col cols="12" sm="6">
          <c-text-currency :disabled="!isEditable" v-model="data.minuto_hora_extra" :rules="[]" placeholder="Ejemplo 5"
            label="Cuantos minutos de margen se tiene en cuenta para horas extras?"></c-text-currency>
        </v-col>
      </v-row>
      <v-row dense class="mx-1">
        <v-col cols="6">
          <span class="title ml-0">Aportes Patronales</span>
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-currency :disabled="!isEditable" v-model="data.aporte_empleador" suffix="%"
                label="Aporte Empleador (Porcentaje)"></c-text-currency>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-currency :disabled="!isEditable" v-model="data.aporte_empleado" suffix="%"
                label="Aporte Empleado (Porcentaje)"></c-text-currency>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <span class="title ml-0">Numeros Patronales</span>
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-field :disabled="!isEditable" v-model="data.nro_patronal_ips"
                label="Numero Patronal IPS"></c-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-field :disabled="!isEditable" v-model="data.nro_patronal_ministerio"
                label="Numero Patronal MTESS"></c-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <span class="title ml-2">Horas de Entrada/Salida</span>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="3">
          <c-text-time :disabled="!isEditable" v-model="entrada" label="Entrada" :rules="[]"></c-text-time>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-time :disabled="!isEditable" v-model="salida" label="Salida" :rules="[]"></c-text-time>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn :disabled="!isEditable" fab text small color="deep-purple accent-4" class="ml-2 mt-2" outlined
            @click="addDetalle()">
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Entrada</th>
                  <th class="text-left">Salida</th>
                  <th class="text-left">Tipo</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data.detalle" :key="item.tipo">
                  <td>{{ item.entrada }}</td>
                  <td>{{ item.salida }}</td>
                  <td>{{ configTipo2(item.tipo) }}</td>
                  <td>
                    <v-btn color="red" fab text x-small @click="deleteRow(item)" :disabled="!isEditable">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <span class="title ml-2">Tipo de Recibo</span>
      <v-row dense class="mx-1">
        <v-col cols="12" sm="12">
          <v-autocomplete return-object v-model="data.recibo" :disabled="!isEditable" :rules="[]" :items="recibos" item-text="name">

          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12">
          <v-img v-if="data.recibo != null" max-width="500" :src="require(`@/assets/help/ch/${data.recibo.image}`)"></v-img>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions v-if="isEditable">
      <v-divider></v-divider>
      <v-btn color="red" class="ml-2" text @click="(isEditable = false), fetchConfigRrhh()">
        Cancelar
      </v-btn>
      <v-btn color="deep-purple accent-4" class="ml-2" text @click="save()">
        Guardar Cambios
      </v-btn>
    </v-card-actions>
    <c-overlay :value="isConfigRrhhLoading"></c-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import autocompleteCuentabanco from "@/views/Apertura/Cuentabanco/autocomplete";
import autocompleteCaja from "@/views/Apertura/Caja/autocomplete";
import { CONFIG_RRHH } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    "autocomplete-cuentabanco": autocompleteCuentabanco,
    "autocomplete-caja": autocompleteCaja,
  },
  data: () => ({
    isEditable: false,
    headers: [
      {
        "text": "Elemento",
        "align": "left",
        "value": "text"
      },
      {
        "text": "Alias",
        "align": "left",
        "value": "value"
      },
      {
        "text": "Activado",
        "align": "right",
        "value": "activated"
      }
    ],

    data: {
      aporte_empleado: "",
      minuto_tolerancia: "",
      minuto_hora_extra: "",
      aporte_empleador: "",
      bonificacion_familiar: "",
      domingo_feriado: "",
      extra_diurna: "",
      extra_nocturna: "",
      extra_nocturna_feriado_domingo: "",
      hora_nocturna: "",
      idconfiguracion: "",
      salario_minimo: "",
      nro_patronal_ips: "",
      nro_patronal_ministerio: "",
      idcuentabanco: {
        idcuentabanco: "",
      },
      configuracioncaja: [
        {
          idcaja: {
            idcaja: "",
          },
        },
      ],
      detalle: [],
      recibo: null,
    },
    entrada: "",
    salida: "",
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    recibos: [
      {
        id: 1,
        name: "Recibo Simple 1",
        image: "rec1.png"
      },
      {
        id: 2,
        name: "Recibo Simple 2",
        image: "rec2.png"
      },
      {
        id: 3,
        name: "Recibo Simple 3",
        image: "rec3.png"
      }
    ]
  }),
  mounted() {
    this.fetchConfigRrhh();
  },
  created() {
    const permission = getPermission(CONFIG_RRHH).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  watch: {
    getConfigRrhh(val) {
      this.data = JSON.parse(JSON.stringify(val));
      if (this.data.configuracioncaja.length == 0) {
        this.data.configuracioncaja = [
          {
            idcaja: {
              idcaja: "",
            },
          },
        ];
      }
    },
  },
  computed: {
    ...mapGetters("configrrhh", ["getConfigRrhh", "isConfigRrhhLoading"]),
  },
  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh", "setConfigRrhhUpdate"]),
    save2(val) {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
      this.pickItem(val)
      
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close() {
      console.log('Dialog closed')
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      if (this.data.idcuentabanco.idcuentabanco === "")
        this.data.idcuentabanco = null;
      if (this.data.minuto_tolerancia === "")
        this.data.minuto_tolerancia = null;
      if (this.data.minuto_hora_extra === "")
        this.data.minuto_hora_extra = null;
      const response = await this.setConfigRrhhUpdate({ data: this.data });
      if (response.success) {
        this.isEditable = false;
      }
    },
    addDetalle() {
      let entrada = this.entrada;
      let salida = this.salida;
      let tipo = this.configTipo(entrada, salida);
      if (!this.isRepeat(tipo)) {
        this.data.detalle.push({
          entrada: entrada,
          salida: salida,
          tipo: tipo,
        });
      } else {
        this.data.detalle.forEach(function (obj) {
          if (obj.tipo === tipo) {
            obj.salida = salida;
            obj.entrada = entrada;
          }
        });
      }
    },
    isRepeat(tipo) {
      return this.data.detalle.find((x) => x.tipo === tipo);
    },
    configTipo(entrada, salida) {
      if ((entrada > salida) | (entrada > '12:00:00')) {
        return false;
      } else {
        return true;
      }
    },
    configTipo2(tipo) {
      if (tipo) {
        return "Mañana";
      } else {
        return "Tarde/Noche";
      }
    },
    deleteRow() {
      console.log("hola");
    },
  },
};
</script>
