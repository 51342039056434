<template>
    <v-dialog :value="value" persistent max-width="900">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-row dense>
                        <v-col cols="6" md="3" sm="3">
                            <c-text-date label="Fecha" :readonly="readonly" id="input0" v-model="data.fecha"
                                @keyup.native.enter="next(0, data.fecha)"></c-text-date>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" md="6" sm="6">
                            <vue-autocomplete-contacto-search :readonly="readonly" label="Proveedor" id="input1"
                                action="contacto/Vact_contacto_data_proveedor"
                                getter="contacto/Vget_contacto_data_proveedor" load="contacto/Vget_contacto_load"
                                vuex="contacto/contacto_create" permission="Proveedores" v-model="data.idcontacto"
                                @keyup.native.enter="next(1, data.idcontacto)" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-text-field :readonly="readonly" id="input2" :rules="false" label="Timbrado"
                                v-mask="mask.tim" v-model="data.timbrado"
                                @keyup.native.enter="next(2, data.timbrado)"></vue-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-text-field :readonly="readonly" id="input3" :rules="false" label="Nro Nota remision"
                                v-mask="mask.fac" v-model="data.nronotaremision"
                                @keyup.native.enter="next(3, data.nronotaremision)"></vue-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <v-autocomplete dense :readonly="readonly" v-model="data.idmotivotraslado" id="input4"
                                :items="Vget_motivotraslado_data" label="Motivo Traslado" item-text="descmotivotraslado"
                                :search-input.sync="searchmotivo" return-object :loading="Vget_motivotraslado_load"
                                @keyup.native.enter="next(4, data.idmotivotraslado.idmotivotraslado)"
                                item-value="idmotivotraslado" outlined autocomplete="off"
                                :no-data-text="`No existe ninguna concidencia`">
                                <template v-if="item_permission" v-slot:no-data>
                                    <v-list-item @click="formularios.motivo = true">
                                        <v-list-item-content>
                                            <v-list-item-title>No existe coincidencia. Click para
                                                agregar</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <div v-if="formularios.motivo">
                                <Stock-Motivotraslado-Crud :sync-input="searchmotivo" :readonly="readonly"
                                    @sync-input="data.idmotivotraslado = $event" v-model="formularios.motivo"
                                    :crudMode="true" />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <v-checkbox v-model="data.comprapedido" :readonly="readonly"
                                :label="data.comprapedido ? `Compra` : `Pedido`"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <div v-if="data.comprapedido == true">
                                <div v-if="compra == null">
                                    <Compra :idproveedor="data.idcontacto" @selectedCompra="compra = $event" />
                                </div>
                                <div v-else>
                                    <v-list subheader two-line>
                                        <v-subheader inset>Compra Seleccionado</v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="compra.idcontacto.idcontacto.desccontacto"></v-list-item-title>
                                                <v-list-item-subtitle
                                                    v-text="`${compra.regi_compra} - ${compra.numfcompra}`"></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon @click="compra = null" :disabled="readonly">
                                                    <v-icon color="error">arrow_back_ios</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="pedido == null">
                                    <Pedido :idproveedor="data.idcontacto" @selectedPedido="pedido = $event" />
                                </div>
                                <div v-else>
                                    <v-list subheader two-line>
                                        <v-subheader inset>Pedido Seleccionado</v-subheader>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="pedido.idcontacto.desccontacto"></v-list-item-title>
                                                <v-list-item-subtitle v-text="pedido.numpedido"></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon @click="pedido = null" :disabled="readonly">
                                                    <v-icon color="error">arrow_back_ios</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="pendiente.length >= 1">
                            <v-data-table :headers="headers2" :items="pendiente"></v-data-table>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-autocomplete-contacto-search :readonly="readonly" label="Transportadora" id="input5"
                                action="contacto/Vact_contacto_data_transportadora"
                                getter="contacto/Vget_contacto_data_transportadora" load="contacto/Vget_contacto_load"
                                vuex="contacto/contacto_create" permission="Transportadora"
                                v-model="data.idtransportadora" @keyup.native.enter="next(5, data.idtransportadora)" />
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <v-autocomplete :readonly="readonly" dense v-model="data.idvehiculo" id="input6"
                                :items="Vget_vehiculo_data" label="Vehiculo" item-text="chapa"
                                :search-input.sync="searchvehiculo" return-object :loading="Vget_vehiculo_load"
                                item-value="idvehiculo" outlined autocomplete="off"
                                @keyup.native.enter="next(6, data.idvehiculo)"
                                :no-data-text="`No existe ninguna coincidencia`">
                                <template v-if="item_permission" v-slot:no-data>
                                    <v-list-item @click="formularios.vehiculo = true">
                                        <v-list-item-content>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No existe coincidencia. Click para agregar
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <div v-if="formularios.vehiculo">
                                <Mantenimientos-Vehiculo-Crud :sync-input="searchvehiculo"
                                    @sync-input="data.idvehiculo = $event" v-model="formularios.vehiculo"
                                    :crudMode="true" />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                            <vue-autocomplete-contacto-search :readonly="readonly" label="Conductor" id="input7"
                                action="contacto/Vact_contacto_data_conductor"
                                getter="contacto/Vget_contacto_data_conductor" load="contacto/Vget_contacto_load"
                                vuex="contacto/contacto_create" permission="Transportadora" v-model="data.idconductor"
                                @keyup.native.enter="next(7, data.idconductor)" />
                        </v-col>
                        <v-col cols="12">
                            <vue-text-field :readonly="readonly" id="input8" :rules="false" label="Comentario"
                                v-model="data.compnotaremision"
                                @keyup.native.enter="next(8, data.compnotaremision)"></vue-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-form ref="formDet">
                    <v-row dense v-if="!readonly">
                        <v-col cols="12" md="6" sm="6">
                            <v-autocomplete dense v-model="detalle.iditem" id="input9" v-bind:items="item"
                                :filter="customFilter" :search-input.sync="searchitem" label="Item" item-text="descitem"
                                return-object :loading="Vget_item_load" item-value="iditem" outlined autocomplete="off"
                                @keyup.enter="next(9, detalle.iditem.iditem)" @change="obtenerPrecio()"
                                :no-data-text="`No existe ninguna coincidencia`" v-slot:no-data>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                            <vue-text-currency label="Precio" id="input10" v-model="detalle.precio" :rules="true"
                                :rules-option="validarPrecio"
                                @keyup.native.enter="next(10, detalle.precio)"></vue-text-currency>
                        </v-col>
                        <v-col cols="8" sm="2" md="2">
                            <vue-text-currency label="Cantidad" id="input11" v-model="detalle.cantidad" :rules="true"
                                :rules-option="validarCantidad"
                                @keyup.native.enter="next(11, detalle.cantidad)"></vue-text-currency>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" id="input12" small fab outlined text @click="addTable()">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-row>
                    <v-data-table :headers="headers" :items="data.notaremisiondet">
                        <template v-slot:[`item.accion`]="props">
                            <v-icon small class="mr-1" @click="editarDetalle(props.item)">edit</v-icon>
                            <v-icon small class="mr-1" @click="eliminarDetalle(props.item)">delete</v-icon>
                        </template>
                    </v-data-table>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="red" text @click="cancelar()">
                    <v-icon>clear</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="guardar()" :disabled="readonly">
                    <v-icon>done</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { current_date } from "@/services/util/date.service"
import { focus } from "@/services/focus.service"
import { mensaje } from "@/services/notify.service"
import { ITEM } from "@/constants/forms"
import { PUEDE_AGREGAR } from '@/constants/permissions'
import { getPermission } from "@/services/storage/permissionStorage.service"
import Compra from './Compra.vue';
import Pedido from './Pedido.vue';
import { mask } from 'vue-the-mask';
export default {
    components: {
        Compra,
        Pedido
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        datos: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    directives: {
        mask,
    },
    data() {
        return {
            item: [],
            searchItemId: null,
            searchitem: "",
            searchmotivo: "",
            searchvehiculo: "",
            compra: null,
            pedido: null,
            pendiente: [],
            mask: {
                tim: "##.###.###",
                fac: "###-###-#######"
            },
            formularios: {
                motivo: false,
                vehiculo: false,
                item: false,
            },
            validarPrecio: [
                () =>
                    !(Number(this.detalle.precio) <= 0) ||
                    "Este campo no puede ser menor o igual a 0",
                () => !!this.detalle.precio || "Este campo es requerido"
            ],
            validarCantidad: [
                () =>
                    !(Number(this.detalle.cantidad) < 1) ||
                    "Este campo no puede ser menor a 1",
                () => !!this.detalle.cantidad || "Este campo es requerido"
            ],
            data: {
                idnotaremision: 0,
                fecha: current_date(),
                idcontacto: {
                    idcontacto: ""
                },
                timbrado: "",
                nronotaremision: "",
                compnotaremision: "",
                idmotivotraslado: {
                    idmotivotraslado: ""
                },
                comprapedido: false,
                idvehiculo: {
                    idvehiculo: ""
                },
                idtransportadora: {
                    idcontacto: ""
                },
                idconductor: {
                    idcontacto: ""
                },
                notaremisiondet: [],
                notaremisionpedido: [],
                notaremisioncompra: [],
            },
            default: {
                idnotaremision: 0,
                fecha: current_date(),
                idcontacto: {
                    idcontacto: ""
                },
                timbrado: "",
                nronotaremision: "",
                compnotaremision: "",
                idmotivotraslado: {
                    idmotivotraslado: ""
                },
                comprapedido: false,
                idvehiculo: {
                    idvehiculo: ""
                },
                idtransportadora: {
                    idcontacto: ""
                },
                idconductor: {
                    idcontacto: ""
                },
                notaremisiondet: [],
                notaremisionpedido: [],
                notaremisioncompra: [],
            },
            detalle: {
                iditem: {
                    iditem: ""
                },
                cantidad: 0,
                precio: 0,
                subtotal: 0,
            },
            detalle_default: {
                iditem: {
                    iditem: ""
                },
                cantidad: 0,
                precio: 0,
                subtotal: 0,
            },
            headers: [
                {
                    text: "Producto",
                    align: "left",
                    value: "iditem.descitem",
                },
                {
                    text: "Cantidad",
                    align: "right",
                    sortable: false,
                    value: "cantidad",
                },
                {
                    text: "Precio",
                    align: "right",
                    sortable: false,
                    value: "precio",
                },
                {
                    text: "",
                    align: "right",
                    sortable: false,
                    value: "accion",
                }
            ],
            headers2: [
                {
                    text: "Producto",
                    align: "left",
                    value: "name",
                },
                {
                    text: "Cantidad",
                    align: "right",
                    value: "cantidad",
                },
                {
                    text: "Cantidad Remitida",
                    align: "right",
                    value: "cantidad_remitida",
                },
                {
                    text: "Precio",
                    align: "right",
                    value: "costo",
                }
            ],
            operacion: true,
        }
    },
    watch: {
        Vget_item_data(val) {
            this.item = []
            this.item = JSON.parse(JSON.stringify(val))
            if (this.searchItemId != null) return this.search()
        },
        Vget_notaremision_pendiente(val) {
            this.pendiente = val
        },
        Vget_notaremision_byId(val) {
            this.data = val
        },
        searchitem(val) {
            this.obtain_item(val)
        },
        searchItemId() {
            this.search();
        },
        pedido(val) {
            //hacer consulta de los detalles de pedido ya verificar si ya hay notas con el pedido
            if (val != null) {
                this.compra = null
                this.Vact_notaremision_pendiente({ 'tipo': 'pedido', 'id': val.idcomprapedido })
                //this.Vact_pedidocompra_byId(val.idcomprapedido)
            } else {
                this.pendiente = []
            }
        },
        compra(val) {
            //hacer consulta de los detalles de compra y verificar si ya hay notas con la compra
            if (val != null) {
                this.pedido = null
                this.Vact_notaremision_pendiente({ 'tipo': 'compra', 'id': val.idcompra })
                //this.Vact_compra_byId(val.idcompra)
            } else {
                this.pendiente = []
            }
        }
    },
    async mounted() {
        this.Vact_motivotraslado_data()
        this.Vact_vehiculo_data()
        if (this.datos != null) {
            await this.Vact_notaremision_byId(this.datos.idnotaremision)
            //this.data = JSON.parse(JSON.stringify(this.datos))
            if (this.data.notaremisionpedido.length >= 1) {
                for (let index = 0; index < this.data.notaremisionpedido.length; index++) {
                    this.pedido = this.data.notaremisionpedido[index].idcomprapedido
                }
            }
            if (this.data.notaremisioncompra.length >= 1) {
                for (let index = 0; index < this.data.notaremisioncompra.length; index++) {
                    this.compra = this.data.notaremisioncompra[index].idcompra
                }
            }
            this.operacion = false
        }
        if (this.syncInput != null) {
            this.data.descnotaremision = this.syncInput
            this.sync = this.searchInput
        }
        setTimeout(() => document.getElementById("input0").focus(), 200)
    },
    computed: {
        ...mapGetters("notaremision", ["Vget_notaremision_save_load", "Vget_notaremision_pendiente", "Vget_notaremision_byId"]),
        ...mapGetters("pedidocompra", ["Vget_pedidocompra_byId"]),
        ...mapGetters("compra", ["Vget_compra_byId"]),
        ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
        ...mapGetters("motivotraslado", ["Vget_motivotraslado_load", "Vget_motivotraslado_data"]),
        ...mapGetters("vehiculo", ["Vget_vehiculo_load", "Vget_vehiculo_data"]),
        titulo() {
            if (this.readonly) {
                return "Ver Nota Remision"
            }
            if (this.operacion) {
                return "Agregar Nota Remision"
            }
            return "Modificar Pedido"
        },
        item_permission() {
            return getPermission(ITEM).permiso[PUEDE_AGREGAR]
        }
    },
    methods: {
        ...mapActions("notaremision", [
            "Vact_notaremision_save",
            "Vact_notaremision_data",
            "Vact_notaremision_pendiente",
            "Vact_notaremision_byId",
            "Vact_notaremision_update"
        ]),
        ...mapActions("pedidocompra", ["Vact_pedidocompra_byId"]),
        ...mapActions("compra", ["Vact_compra_byId"]),
        ...mapActions("item", ["Vact_item_data_nota", "item_update"]),
        ...mapActions("motivotraslado", ["Vact_motivotraslado_data"]),
        ...mapActions("vehiculo", ["Vact_vehiculo_data"]),
        obtain_item(val) {
            if (val != null && val.length == 3) {
                try {
                    let band = this.data.comprapedido ? 'compra' : 'pedido'
                    let clave = this.data.comprapedido ? this.compra.idcompra : this.pedido.idcomprapedido
                    this.Vact_item_data_nota({
                        desc: this.searchitem,
                        band: band,
                        clave: clave
                    })
                } catch (e) {
                    console.log(e)
                    return this.$notify(mensaje.error("Seleccione una compra o un pedido"))
                }
            } else if (val != null && val.length < 3) {
                this.item = []
            } else if (val == null) {
                this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
            }
        },
        customFilter(item, queryText) {
            const textOne = item.iditem.toString();
            const textTwo = item.descitem.toLowerCase();
            const searchText = queryText.toLowerCase();
            return (
                textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
            )
        },
        search() {
            this.item.forEach((x) => {
                if (x.iditem === this.searchItemId) {
                    this.detalle.precio = x.precitem
                    return
                }
            })
        },
        cancelar() {
            this.Vact_notaremision_data({ limit: 100, desc: null })
            this.$emit("input", false)
            this.$emit("datos", null)
        },
        next(id, data) {
            if (data === "" || data <= 0) return null
            focus.nextid(id, data)
        },
        obtenerPrecio() {
            if (this.pendiente.length == 0) {
                if (this.detalle.iditem.idunidadmedida == null || this.detalle.iditem.idunidadmedida == undefined) {
                    this.item_update(this.detalle.iditem)
                    this.searchitem = this.detalle.iditem.descitem
                    this.formularios.item = true
                    this.$notify(mensaje.error("Verifique la unidad de medida"))
                }
                if (this.detalle.iditem.precitem != undefined) {
                    this.detalle.precio = this.detalle.iditem.precitem
                }
            } else {
                this.pendiente.forEach(element => {
                    if (element.id == this.detalle.iditem.iditem) {
                        this.detalle.precio = element.costo
                    }
                })
            }

        },
        isExist() {
            let exist = false
            this.data.notaremisiondet.forEach(item => {
                if (item.iditem.iditem === this.detalle.iditem.iditem) {
                    exist = true
                }
            })
            return exist
        },
        isValidDetail() {
            if (!this.$refs.formDet.validate() || this.isExist()) {
                return false
            }
            return true
        },
        validarRemitidos(val) {
            const item = this.pendiente.find(element => val.iditem.iditem === element.id)
            if (!item) {
                return [false, 'No existe el item en compra/pedido']
            }
            return val.cantidad + item.cantidad_remitida <= item.cantidad
                ? [true, null]
                : [false, 'Ya supera las cantidades en compra/pedido']
        },
        addTable() {
            if (!this.isValidDetail()) {
                return this.$notify(mensaje.error("Verifique los datos ingresados"))
            }
            const validar = this.validarRemitidos(this.detalle)
            if (!validar[0]) {
                return this.$notify(mensaje.error(validar[1]))
            }
            this.detalle.subtotal = (
                Number(this.detalle.precio) * Number(this.detalle.cantidad)
            ).toFixed(2)
            this.data.notaremisiondet.push(this.detalle)
            this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
            this.$refs.formDet.resetValidation()
            setTimeout(() => document.getElementById("input9").focus(), 200)
        },
        async guardar() {
            if (this.data.comprapedido == true) {
                this.data.notaremisioncompra = []
                this.data.notaremisioncompra.push({ idcompra: this.compra })
            } else {
                this.data.notaremisionpedido = []
                this.data.notaremisionpedido.push({ idcomprapedido: this.pedido })
            }
            if (this.data.notaremisiondet.length == 0) {
                return this.$notify(mensaje.detalle())
            }
            if (!this.$refs.form.validate()) {
                return this.$notify(mensaje.completar())
            }
            const response = this.operacion
                ? await this.Vact_notaremision_save(this.data)
                : await this.Vact_notaremision_update({ id: this.data.idnotaremision, data: this.data })
            if (response.success) {
                this.$refs.form.resetValidation()
                this.limpiarCampos()
                setTimeout(() => document.getElementById("input1").focus(), 200)
            }
            if (!this.operacion) this.cancelar()
        },
        limpiarCampos() {
            this.data = JSON.parse(JSON.stringify(this.default))
            this.detalle = JSON.parse(JSON.stringify(this.detalle_default))
        },
        editarDetalle(item) {
            let band = this.data.comprapedido ? 'compra' : 'pedido'
            let clave = this.data.comprapedido ? this.compra.idcompra : this.pedido.idcomprapedido
            this.Vact_item_data_nota({
                desc: item.iditem.descitem,
                band: band,
                clave: clave
            })
            const index = this.data.notaremisiondet.indexOf(item)
            this.data.notaremisiondet.splice(index, 1)
            this.detalle = JSON.parse(JSON.stringify(item))
        },
        eliminarDetalle(item) {
            const index = this.data.notaremisiondet.indexOf(item)
            this.data.notaremisiondet.splice(index, 1)
        }
    }
}
</script>