export const [
  JORNAL_HORA,
  JORNAL_DIARIO,
  BONIFICACION,
  APORTE_IPS_EMPLEADO,
  SUSPENCION,
  AUSENCIA_INJUSTIFICADA,
  LLEGADA_TARDIA,
  ANTICIPO,
] = [
  "JORNAL HORA",
  "JORNAL DIARIO",
  "BONIFICACION",
  "APORTE IPS EMP",
  "SUSPENCION",
  "AUSENCIA_INJUSTIFICADA",
  "LLEGADA TARDIA",
  "ANTICIPO",
];
export const configurarConcepto = (concepto, config) => {
  switch (concepto.idconcepto_salario) {
    case 2:
      //Hora Nocturna
      concepto.descripcion = `${concepto.descripcion} (${config.hora_nocturna}%)`;
      concepto.label = "Horas";
      concepto.calculo = JORNAL_HORA;
      concepto.porcentaje = config.hora_nocturna;
      break;
    case 3:
      //Extra Diurna
      concepto.descripcion = `${concepto.descripcion} (${config.extra_diurna}%)`;
      concepto.label = "Horas";
      concepto.calculo = JORNAL_HORA;
      concepto.porcentaje = config.extra_diurna;
      break;
    case 4:
      //Extra Nocturna
      concepto.descripcion = `${concepto.descripcion} (${config.extra_nocturna}%)`;
      concepto.label = "Horas";
      concepto.calculo = JORNAL_HORA;
      concepto.porcentaje = config.extra_nocturna;
      break;
    case 5:
      //Domingos y Feriados
      concepto.descripcion = `${concepto.descripcion} (${config.domingo_feriado}%)`;
      concepto.label = "Horas";
      concepto.calculo = JORNAL_HORA;
      concepto.porcentaje = config.domingo_feriado;
      break;
    case 6:
      //Extra Nocturna & Domingos y Feriados
      concepto.descripcion = `${concepto.descripcion} (${config.extra_nocturna_feriado_domingo}%)`;
      concepto.label = "Horas";
      concepto.calculo = JORNAL_HORA;
      concepto.porcentaje = config.extra_nocturna_feriado_domingo;
      break;
    case 7:
      //Vacaciones
      concepto.label = "Dias";
      concepto.calculo = JORNAL_DIARIO;
      break;
    case 8:
      //Bonificacion Familiar
      concepto.descripcion = `${concepto.descripcion} (${config.bonificacion_familiar}%)`;
      concepto.label = "Cantidad de hijos";
      concepto.calculo = BONIFICACION;
      concepto.porcentaje = config.bonificacion_familiar;
      break;
    case 9:
      //Otros Ingresos
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 10:
      //Aporte IPS
      concepto.descripcion = `${concepto.descripcion} (${config.aporte_empleado}%)`;
      concepto.porcentaje = config.aporte_empleado;
      concepto.calculo = APORTE_IPS_EMPLEADO;
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 11:
      //Anticipo
      concepto.calculo = ANTICIPO;
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 12:
      //Asociaciones y Cooperativas
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 13:
      //Prestamos
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 14:
      //Judiciales
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    case 15:
      //Suspenciones
      concepto.label = "Dias";
      concepto.calculo = SUSPENCION;
      break;
    case 16:
      //Suspenciones
      concepto.label = "Dias";
      concepto.calculo = AUSENCIA_INJUSTIFICADA;
      break;
    case 17:
      //Llegadas Tardias
      concepto.label = "Horas";
      concepto.calculo = LLEGADA_TARDIA;
      break;
    case 18:
      //Otro descuento
      concepto.cantDisabled = true;
      concepto.cantidad = null;
      break;
    default:
  }
  return concepto;
};

export const obtenerDatosFuncionario = (datos) => {
  const temp = datos.detalle.filter(
    (x) => x.idconcepto_salario.idconcepto_salario === 1
  );
  let jornaldiario = 0;
  if (temp[0].idunidadmedida)
    switch (temp[0].idunidadmedida.idunidadmedida) {
      case 17:
        jornaldiario = temp[0].monto / 30;
        break;
      case 15:
        jornaldiario = temp[0].monto;
        break;
      case 14:
        jornaldiario = temp[0].monto * 8;
        break;
      default:
        jornaldiario = 0;
    }
  const salario = {
    base: temp[0].monto,
    diario: jornaldiario,
    hora: jornaldiario / 8,
    anticipo: datos.anticipo,
  };
  const unidad = temp[0].idunidadmedida;
  const asistencia = {
    dias: datos.dias_trabajo,
    horas: datos.asistencia - datos.total_horaextra,
    total: {
      asistencia: datos.asistencia,
      extra: datos.horaextra,
    },
    extra: {
      diurna: datos.extra_diurna,
      nocturna: datos.extra_nocturna,
      domingo: datos.extra_domingo,
    },
    domingoferiado: datos.domingo,
    tardia: datos.tardia,
    ausencia: datos.ausencias,
  };
  const info = { hijos: datos.hijos };
  return { salario, unidad, asistencia, info };
};

export const calcularMonto = (salario, concepto, cantidad) => {
  switch (concepto.calculo) {
    case JORNAL_HORA:
      return Number.parseInt(
        ((salario.hora * concepto.porcentaje) / 100 + salario.hora) * cantidad
      );
    case JORNAL_DIARIO:
      return Number.parseInt(cantidad * salario.diario);
    case BONIFICACION:
      return Number.parseInt(((salario.base * concepto.porcentaje) / 100) * cantidad);
    case APORTE_IPS_EMPLEADO:
      return Number.parseInt((salario.ips * concepto.porcentaje) / 100);
    case SUSPENCION:
      return Number.parseInt(salario.diario * cantidad);
    case ANTICIPO:
      return Number.parseInt(salario.anticipo);
    case AUSENCIA_INJUSTIFICADA:
      return Number.parseInt(salario.diario * cantidad);
    case LLEGADA_TARDIA:
      return Number.parseInt(salario.hora * cantidad);
    default:
      return Number.parseInt(cantidad * salario.base);
  }
};

export const obtenerCantidadMonto = (concepto, funcionario, monto) => {
  let cant;
  const salario = funcionario.salario;

  switch (concepto.idconcepto_salario) {
    case 1:
      if (funcionario.unidad)
        switch (funcionario.unidad.idunidadmedida) {
          case 17:
            return {
              disabledMonto: true,
              active: true,
              cantidad: 1,
              monto: calcularMonto(salario, concepto, 1),
            };
          case 15:
            cant = funcionario.asistencia.dias;
            return {
              disabledMonto: true,
              active: true,
              cantidad: cant,
              monto: calcularMonto(salario, concepto, cant),
            };
          case 14:
            cant = funcionario.asistencia.horas;
            return {
              disabledMonto: true,
              active: true,
              cantidad: cant,
              monto: calcularMonto(salario, concepto, cant),
            };
        }
      break;
    case 3:
      cant = funcionario.asistencia.extra.diurna;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 4:
      cant = funcionario.asistencia.extra.nocturna;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 5:
      cant = funcionario.asistencia.domingoferiado;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 6:
      cant = funcionario.asistencia.extra.domingo;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 8:
      cant = funcionario.info.hijos;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 10:
      return { disabledMonto: true, active: true, cantidad: null, monto: 0 };
    case 11:
      return {
        active: salario.anticipo > 0 ? true : false,
        cantidad: null,
        monto: calcularMonto(salario, concepto),
      };
    case 16:
      cant = funcionario.asistencia.ausencia;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    case 17:
      cant = funcionario.asistencia.tardia;
      if (cant === 0) break;
      return {
        active: true,
        cantidad: cant,
        monto: calcularMonto(salario, concepto, cant),
      };
    default:
      return {
        active: !monto ? false : true,
        cantidad: !monto ? null : 1,
        monto: monto || null,
      };
  }
  return {
    active: false,
    cantidad: null,
    monto: "",
  };
};

export const calcularAporteEmp = (conceptosActivos) => {
  return conceptosActivos.reduce((acc, curr) => {
    if (curr.active && curr.idconcepto_salario.idconcepto_salario != 8)
      return (acc = acc + Number(curr.monto));
    return acc;
  }, 0);
};
