<template>
  <div>
    <vue-autocomplete-item-agrostock
      :id="id"
      ref="input"
      label="Producto"
      :readonly="readonly"
      :value="value"
      :search-item-id="itemCreado"
      @input="$emit('input', $event)"
      @create="formulario = $event"
      @sync-input="search = $event"
      @focus="$emit('focus')"
      :key="key"
    ></vue-autocomplete-item-agrostock>
    <Crud
      v-if="formulario"
      v-model="formulario"
      @item="itemCreado = $event"
      :sync-input="search"
      crudMode="AGRO"
    />
  </div>
</template>
<script>
import Crud from "@/views/Item/Item/Create";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: String,
    readonly: Boolean,
    value: [Object, String, Number],
    operacion: Boolean,
    tipoItemSeleccionado: Number,
    isMultipleLote: Boolean,
    editDetail: Boolean,
    siembra: Object,
    idparcelas: [Object, String],
    resetItem: Boolean,
  },
  components: {
    Crud,
  },
  data: () => ({
    formulario: false,
    search: null,
    itemCreado: null,
    key: 1,
  }),
  watch: {
    formulario(val) {
      if (!val) {
        this.key++;
        this.$emit("nextfocus");
      }
    },
    resetItem(val) {
      if (val == true) this.itemCreado = null;
    },
    value() {
      this.obtenerHectareaLote();
      this.obtenerUnidadMedidaProducto();
    },
    parcela() {
      this.obtenerHectareaLote();
    },
  },
  computed: {
    ...mapGetters("siembra", ["getHaSembrable"]),
  },
  methods: {
    ...mapActions("siembra", ["fetchHaSembrable"]),
    ...mapActions("unidadmedida", ["Vact_unidadmedida_data_magnitud", "Vact_unidadmedida_data"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    async obtenerHectareaLote() {
      if(!this.operacion) return null;
      if (this.tipoItemSeleccionado === 5 && !this.isMultipleLote) {
        const idlote = this.siembra.idlote_agri
          ? this.siembra.idlote_agri.idlote_agri
          : this.siembra.idlote_agri.idlote_agri;
        const iditem = this.value ? `${this.value.iditem.iditem}` : "";
        if (iditem === "") {
          console.log(this.siembra.idlote_agri.hectarea)
          return this.setHectareaDisponible(
            this.siembra.idlote_agri.hectarea
          );
        }
        const idparcela = this.idparcelas.idparcelas
          ? this.idparcelas.idparcelas
          : 0;
        await this.fetchHaSembrable({
          idlote,
          idcampana: this.siembra.idcampana.idcampana,
          iditem,
          idparcela,
        });

        if (this.getHaSembrable.length === 0) {
          const ha = !this.siembra.idlote_agri
            ? this.siembra.idlote_agri.hectarea
            : this.siembra.idlote_agri.hectarea;
          console.log(ha)
          return this.setHectareaDisponible(ha);
        }
        console.log(this.getHaSembrable.hectarea)
        return this.setHectareaDisponible(this.getHaSembrable.hectarea);
      }
      const ha = !this.siembra.idlote_agri
        ? this.siembra.idlote_agri.hectarea
        : this.siembra.idlote_agri.hectarea;
      const haParcela = this.idparcelas
        ? this.idparcelas.hectarea
          ? this.idparcelas.hectarea
          : undefined
        : undefined;
      console.log(ha, haParcela)
      this.setHectareaDisponible(haParcela || ha);
    },

    obtenerUnidadMedidaProducto() {
      if (this.value !== undefined) {
        let idmagnitud = null
        if (this.value.iditem.idunidadmedida) {
          idmagnitud = this.value.iditem.idunidadmedida.idmagnitud.idmagnitud;
        }
        if (idmagnitud) {
          this.Vact_unidadmedida_data_magnitud(idmagnitud);  
          if (!this.editDetail) {
            this.setUnidadMedida(undefined);
            this.setUnidadMedida(this.value.iditem.idunidadmedida);
          }
        } else {
          this.Vact_unidadmedida_data();
        }
        this.setTipoItemSeleccionado(this.value.iditem.idtipo_item.idtipo_item);
      }
    },
    setHectareaDisponible(disponible) {
      this.$emit("hectarea", disponible);
    },
    setUnidadMedida(idunidadmedida) {
      this.$emit("unidadmedida", idunidadmedida);
    },
    setTipoItemSeleccionado(tipo) {
      this.$emit("tiposeleccionado", tipo);
    },
  },
};
</script>
