<template>
  <v-card>
    <GenerarSenave v-if="generar_senave.show" v-model="generar_senave.show" :value="generar_senave.show"
      :datos="generar_senave.datos" @datos="generar_senave.datos = $event" @cerrar-senave="cerrarSenave"
      @export-senave="exportToExcel" />
    <CopyMove v-if="copymove.show" v-model="copymove.show" :value="copymove.show" :datos="copymove.datos"
      @datos="copymode.datos = $event" @cerrar-todo="cerrarTodo" />
    <SelectRecetario v-if="recetario.show" v-model="recetario.show" :value="recetario.show" :datos="recetario.datos"
      @datos="recetario.datos = $event" :lote="propIdlote" :zafra="idcampana.idcampana" />
    <v-form ref="formCabecera">
      <v-card flat>
        <v-row dense class="mx-1">
          <v-card-title>
            {{ operacion ? "Agregar Aplicacion" : "Editar Aplicacion" }}
            <widgets-Favorite ruta="/agregaraplicacion" formulario="Siembra/Aplicacion" />
          </v-card-title>
          <v-divider class="my-3" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" v-if="operacion">
            <AutocompleteCampana label="Campaña" hide-details :readonly="!readonly" :fetch="false" id="siembraInput1"
              ref="input1" return-object v-model="siembra.idcampana" @change="siembra.idlote_agri = ''"
              @keyup.native.enter="
                next(1, siembra.idcampana.idcampana),
                $refs.input1.isMenuActive()
                " />
          </v-col>
          <v-col cols="12" sm="3" v-if="operacion">
            <AutocompleteLote :items="siembra.idcampana.campanadet" :readonly="!readonly" id="siembraInput2"
              ref="input2" :loading="Vget_campana_load" v-model="siembra.idlote_agri"
              @change="changeLote(siembra.idlote_agri)" @keyup.native.enter="
                next(2, siembra.idlote_agri.idcampana),
                ($refs.input2.isMenuActive = false)
                " />
          </v-col>
          <v-spacer></v-spacer>
          <v-divider class="my-3" inset vertical></v-divider>
          <v-col cols="12" sm="1" v-if="!operacion" class="pa-2" tile>
            <v-tooltip bottom color="info">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" text dark small fab color="primary" v-on="on" @click="copymove.show = true">
                  <v-icon> content_paste </v-icon>
                </v-btn>
              </template>
              <v-icon small color="red" class="mr-2">help_outline</v-icon>
              <span class="text-center">Click para copiar o mover</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="1" v-if="operacion && permission_recetario.can_view" class="pa-2 mt-2" tile>
            <v-tooltip bottom color="info">
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" dark small outlined fab color="primary" v-on="on" @click="recetario.show = true">
                  <v-icon>
                    science
                  </v-icon>
                </v-btn>
              </template>
              <v-icon small color="red" class="mr-2">help_outline</v-icon>
              <span class="text-center">Click para agregar desde recetario</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-container>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" sm="12" v-if="recetario.datos != null">
            <v-alert type="info" dense>
              <v-row align="center">
                <v-col class="grow">
                  Ha seleccionado un recetario
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="quitarRecetario()">Quitar</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" sm="12" v-if="configForm.registrar_responsable">
            <AutocompleteAplicador id="siembraInput3" ref="siembraInput3" outlined dense create
              v-model="siembra.idaplicador" label="Aplicador" @next-focus="next(3, siembra.idaplicador.idaplicador)"
              @keyup.native.enter="
                next(3, siembra.idaplicador.idaplicador),
                ($refs.siembraInput3.isMenuActive = false)
                " />
          </v-col>
          <v-col cols="12" :sm="config.tipoItemSeleccionado === 5 ? 4 : 6" lg="3">
            <c-text-date label="Fecha Inicio" id="siembraInput4" ref="siembraInput4" outlined dense
              :readonly="!readonly" v-model="siembra.fecha" @focus="
                setSelection('siembraInput4'),
                scrollIntoView(operacion ? 'siembraInput2' : 'siembraInput4')
                " @keyup.native.enter="
                  next(4, siembra.fecha), (siembra.fechafin = siembra.fecha)
                  " @blur="siembra.fechafin = siembra.fecha"></c-text-date>
          </v-col>
          <v-col cols="12" :sm="config.tipoItemSeleccionado === 5 ? 4 : 6" lg="3">
            <c-text-date label="Fecha Fin" :rules="[]" outlined dense id="siembraInput5" :readonly="!readonly"
              v-model="siembra.fechafin" @focus="
                setSelection('siembraInput5'), scrollIntoView('siembraInput4')
                " @keyup.native.enter="next(5, 'o')"></c-text-date>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" lg="2" class="mr-5">
            <v-switch class="mt-1" v-model="senave" inset :label="senave ? `SENAVE si` : `SENAVE no`"
              :disabled="!readonly" color="purple"></v-switch>
          </v-col>
          <v-col cols="12" sm="4" v-if="config.tipoItemSeleccionado === 5" lg="2" class="mr-5">
            <v-switch class="mt-1" v-model="siembradet.tiposiembra" inset :label="computedTiposiembra"
              :disabled="!readonly"></v-switch>
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="senave" v-if="senave">
        <v-alert border="right" colored-border color="purple" elevation="2">
          <v-row dense>
            <v-col cols="12" sm="3">
              <AutocompleteAplicador id="senaveInput1" ref="senaveInput1" outlined dense create
                v-model="sen_ave.idaplicador" label="Aplicador"
                @next-focus="next_senave(1, sen_ave.idaplicador.idaplicador)" @keyup.native.enter="
                  next_senave(1, sen_ave.idaplicador.idaplicador)
                  " />
            </v-col>
            <v-col cols="12" sm="3">
              <AutocompleteIngeniero id="senaveInput2" ref="senaveInput2" outlined create dense label="Ingeniero"
                v-model="sen_ave.idlote_ing" @next-focus="next_senave(2, sen_ave.idlote_ing.idlote_ing)"
                @keyup.native.enter="
                  next_senave(2, sen_ave.idlote_ing.idlote_ing)
                  " />
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete id="senaveInput3" ref="senaveInput3" outlined dense :items="[
                { value: true, text: 'SI' },
                { value: false, text: 'NO' },
              ]" item-value="value" item-text="text" v-model="sen_ave.epi" label="Epi"
                @keyup.native.enter="next_senave(3, sen_ave.epi)"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <AutocompleteTipoPico id="senaveInput4" dense outlined create label="Tipo de pico utilizado"
                v-model="sen_ave.idtipo_pico" @next-focus="next_senave(4, sen_ave.idtipo_item.idtipo_pico)"
                @keyup.native.enter="
                  next_senave(4, sen_ave.idtipo_pico.idtipo_pico)
                  " />
            </v-col>
            <v-col cols="12" sm="3">
              <c-text-currency id="senaveInput5" suffix="%" outlined dense label="Humedad" :rules="humedadRules"
                v-model="sen_ave.humedad" @keyup.native.enter="next_senave(5, sen_ave.humedad)"></c-text-currency>
            </v-col>
            <v-col cols="12" sm="3">
              <c-text-currency id="senaveInput6" suffix="ºC" outlined dense v-model="sen_ave.temperatura"
                label="Temperatura" :rules="temperaturaRules"
                @keyup.native.enter="next_senave(6, sen_ave.temperatura)"></c-text-currency>
            </v-col>
            <v-col cols="12" sm="3">
              <c-text-currency id="senaveInput7" outlined dense v-model="sen_ave.agua" label="Volumen Agua"
                @keyup.native.enter="next_senave(7, sen_ave.agua)"></c-text-currency>
            </v-col>
            <v-col cols="12" sm="3">
              <c-text-currency id="senaveInput8" suffix="km/h" outlined dense label="Velocidad Viento"
                :rules="velocidadRules" v-model="sen_ave.velocidad_viento"
                @keyup.native.enter="next_senave(8, sen_ave.velocidad_viento)"></c-text-currency>
            </v-col>
          </v-row>
        </v-alert>
      </v-form>
      <v-form ref="formdetalle">
        <div
          v-if="siembradet.tiposiembra == false && (siembradet.idstock != null && siembradet.idstock.iditem.idtipo_item.idtipo_item == 5)">
          <row v-if="siembradet.replantacion == null">
            <v-col cols="12">
              Elija sobre cual va a realizar la replantacion
            </v-col>
            <v-col cols="12" v-for="i of Vget_item_siembra" :key="i">
              <v-row>
                <v-col cols="12" sm="12" lg="6">
                  {{ i.descitem }}
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <vue-text-currency readonly label="Ha Sembrada" v-model="i.hectarea" :rules="true"
                    type="number"></vue-text-currency>
                </v-col>
                <v-col cols="12" sm="12" lg="2">
                  <v-btn color="primary" fab small dark @click="siembradet.replantacion = i">
                    <v-icon>arrow_forward_ios</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </row>
          <row v-else>
            <v-col cols="12">
              <v-btn color="primary" fab small dark @click="siembradet.replantacion = null">
                <v-icon>arrow_back_ios</v-icon>
              </v-btn>
              Realizara una replantacion sobre {{ siembradet.replantacion.descitem }} que posee {{
                siembradet.replantacion.hectarea }}
            </v-col>
          </row>
        </div>
        <v-row dense>
          <v-col cols="12" sm="3" v-if="configForm.registrar_parcelas">
            <AutocompleteParcela id="siembraInput6" :idcampana="siembra.idcampana" :idlote="idLote" outlined create
              dense label="Parcela" v-model="siembradet.idparcelas" @focus="scrollIntoView('siembraInput5')"
              @next-focus="next(6, '-')" @keyup.native.enter="next(6, siembradet.idparcelas.idparcelas)" />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <AutocompleteTipo id="siembraInput7" ref="input3" :readonly="!readonly"
              v-model="config.tipoItemSeleccionado" @focus="scrollIntoView('siembraInput5')" @keyup.native.enter="
                next(7, config.tipoItemSeleccionado),
                $refs.input3.isMenuActive()
                " />
          </v-col>
          <v-col cols="12" md="5" sm="6">
            <AutocompleteItem id="siembraInput8" ref="siembraInput8" v-model="siembradet.idstock" :operacion="operacion"
              :siembra="siembra" :idparcelas="siembradet.idparcelas" :tipoItemSeleccionado="config.tipoItemSeleccionado"
              :editDetail="editDetailMode" :resetItem="resetItem" @focus="scrollIntoView('siembraInput7')"
              @hectarea="siembradet.hectarea = $event" @unidadmedida="siembradet.idunidadmedida = $event"
              @tiposeleccionado="config.tipoItemSeleccionado = $event" @nextfocus="next(8, '-')" @keyup.native.enter="
                next(8, siembradet.idstock), $refs.siembraInput8.isMenuActive()
                " />
          </v-col>

          <v-col cols="12" md="2" sm="4" v-if="configForm.aplicacion_ha_total">
            <vue-text-field label="Cant. Total" type="number" :rules="true" :readonly="!readonly" id="siembraInput9"
              v-model="siembradet.subtotal" @focus="scrollIntoView('siembraInput8')"
              @keyup.native.enter="next(9, siembradet.subtotal)"></vue-text-field>
          </v-col>

          <v-col cols="12" md="2" sm="4" v-else>
            <vue-text-field label="Cantidad" type="number" :rules="true" :readonly="!readonly" id="siembraInput9"
              v-model="siembradet.cantidad" @focus="scrollIntoView('siembraInput8')"
              @keyup.native.enter="next(9, siembradet.cantidad)"></vue-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="4">
            <AutocompleteUnidad id="siembraInput10" :readonly="siembradet.idstock === undefined ? true : false || !readonly
              " v-model="siembradet.idunidadmedida" ref="unidadmedida" @change="obtenePrecio()"
              @focus="scrollIntoView('siembraInput9')" @keyup.native.enter="
                permission.can_view_numbers == false ? next(11, siembradet.idunidadmedida.idunidadmedida) : next(10, siembradet.idunidadmedida.idunidadmedida);
              $refs.unidadmedida.isMenuActive()
                " />
          </v-col>
          <v-col cols="12" md="3" sm="3" v-if="permission.can_view_numbers == true">
            <vue-text-currency label="Precio" v-model="siembradet.precio" :rules="true" :readonly="!readonly"
              type="number" id="siembraInput11" @focus="setSelection('siembraInput11')"
              @keyup.native.enter="next(11, siembradet.precio)"></vue-text-currency>
          </v-col>
          <v-col cols="7" md="3" sm="3" v-if="
            !isMultipleLote && operacion && config.tipoItemSeleccionado != 10
          ">
            <vue-text-field label="Hectarea" id="siembraInput12" :readonly="!readonly"
              @focus="setSelection('siembraInput12')" v-model="siembradet.hectarea"
              @keyup.native.enter="next(12, siembradet.hectarea)"></vue-text-field>
          </v-col>
          <v-col cols="7" md="3" sm="3" v-if="!operacion && config.tipoItemSeleccionado != 10">
            <v-text-field label="Hectarea" id="siembraInput12" outlined :append-icon="copyAllItems ? 'check_box' : 'check_box_outline_blank'
              " dense :readonly="!readonly" autocomplete="off" v-model="siembradet.hectarea"
              @keyup.native.enter="next(12, siembradet.hectarea)" persistent-hint
              @click:append="copyAllItems = !copyAllItems" :hint="copyAllItems
                ? 'Copiar en todas las filas'
                : 'Aplicar solo a este item'
                "></v-text-field>
          </v-col>

          <v-col cols="12" sm="3" v-if="configForm.registrar_humedad">
            <c-text-currency id="siembraInput13" suffix="%" outlined dense label="Humedad" v-model="siembradet.humedad"
              @keyup.native.enter="next(13, siembradet.humedad)"></c-text-currency>
          </v-col>
          <v-col cols="12" sm="3" v-if="configForm.registrar_epi">
            <v-autocomplete id="siembraInput14" ref="siembraInput14" outlined dense :items="[
              { value: true, text: 'SI' },
              { value: false, text: 'NO' },
            ]" item-value="value" item-text="text" v-model="siembradet.epi" label="Epi" @keyup.enter="
              next(14, '-'), ($refs.siembraInput14.isMenuActive = false)
              "></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" v-if="configForm.registrar_temperatura">
            <c-text-currency id="siembraInput15" suffix="ºC" outlined dense v-model="siembradet.temperatura"
              label="Temperatura" @keyup.native.enter="next(15, siembradet.temperatura)"></c-text-currency>
          </v-col>
          <v-col cols="12" sm="3" v-if="configForm.registrar_tipo_pico">
            <AutocompleteTipoPico id="siembraInput16" dense outlined create label="Tipo de pico utilizado"
              v-model="siembradet.idtipo_pico" @next-focus="next(16, '-')"
              @keyup.native.enter="next(16, siembradet.idtipo_pico.idtipo_pico)" />
          </v-col>
          <v-col cols="12" sm="3" v-if="configForm.registrar_volumen_agua">
            <c-text-currency id="siembraInput17" outlined dense v-model="siembradet.agua" label="Volumen Agua"
              @keyup.native.enter="next(17, siembradet.agua)"></c-text-currency>
          </v-col>
          <v-col cols="12" sm="3" v-if="configForm.registrar_velocidad_viento">
            <c-text-currency id="siembraInput18" suffix="km/h" outlined dense label="Velocidad Viento"
              v-model="siembradet.velocidad_viento"
              @keyup.native.enter="next(18, siembradet.velocidad_viento)"></c-text-currency>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn id="siembraInputaceptar" outlined color="primary" fab small @click="addTable()" :disabled="!readonly">
            <v-icon>add</v-icon>
          </v-btn>
          <v-col cols="12">
            <DataTable :parcela="configForm.registrar_parcelas" :items="siembra.siembradet" :readonly="readonly"
              :permiso="permission" @edit="editarDetalle($event)" @delete="eliminarDetalle($event)" />
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="mb-2"></v-divider>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-col cols="6" sm="3" v-if="permission.can_view_numbers">
          <vue-text-currency label="Precio x Ha" :rules="false" :readonly="true" :value="totalxHa"></vue-text-currency>
        </v-col>
        <v-col cols="6" sm="3" v-if="permission.can_view_numbers">
          <vue-text-currency label="Precio Total" :rules="false" :readonly="true"
            :value="totalgeneral"></vue-text-currency>
        </v-col>
        <v-col cols="12">
          <vue-text-field label="Comentario" :rules="false" v-model="siembra.comentario"
            :readonly="!readonly"></vue-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <c-btn-crud-close @click="cerrar()"></c-btn-crud-close>
      <v-spacer></v-spacer>
      <v-btn color="green" v-if="
        permission.can_reporte &&
        !operacion &&
        siembra.siembrasenave.length >= 1
      " text @click="generarSenave()">Generar Senave</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="red" v-if="permission.can_delete && !operacion" text @click="dialog.delete = true"
        :disabled="!readonly">Eliminar</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
      <c-btn-crud-done @click="guardarSiembra()" :disabled="!readonly"></c-btn-crud-done>
    </v-card-actions>
    <Delete @close="cerrar()" :props="siembra" v-model="dialog.delete" />
    <c-overlay :value="Vget_siembra_save_load || Vget_siembra_load"></c-overlay>
  </v-card>
</template>

<script>
import { exportSenave, exportSenavePDF } from "../Reporte/Senave/export";
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA, RECETARIO } from "@/constants/forms";
import { PUEDE_ELIMINAR, PUEDE_GENERAR_REPORTE, PUEDE_LISTAR, PUEDE_VER_NUMEROS } from "@/constants/permissions";

import AutocompleteTipoPico from "../Mantenimiento/TipoPico/autocomplete";
import AutocompleteCampana from "../Campana/autocomplete";
import AutocompleteLote from "./AutocompleteLote";
import AutocompleteTipo from "./AutocompleteTipo";
import AutocompleteUnidad from "./AutocompleteUnidad";
import AutocompleteItem from "./AutocompleteItem";
import AutocompleteAplicador from "../Mantenimiento/Aplicador/autocomplete";
import AutocompleteParcela from "../Mantenimiento/Parcela/autocomplete";
import AutocompleteIngeniero from "../Mantenimiento/LoteIngeniero/autocomplete";
import DataTable from "./DataTable";
import Delete from "./Delete";
import CopyMove from "./CopyMove";
import SelectRecetario from "./SelectRecetario";
import GenerarSenave from "./GenerarSenave";

export default {
  components: {
    AutocompleteTipoPico,
    AutocompleteCampana,
    AutocompleteLote,
    AutocompleteTipo,
    AutocompleteUnidad,
    AutocompleteItem,
    AutocompleteAplicador,
    AutocompleteParcela,
    AutocompleteIngeniero,
    DataTable,
    Delete,
    CopyMove,
    SelectRecetario,
    GenerarSenave,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idcampana: {
      type: Object,
      required: false,
    },
    idsiembra: {
      type: Number,
      default: null,
    },
    propIdlote: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    'siembradet.idstock'(val) {
      console.log(val)
      if (val != null) {
        let idcampana = this.siembra.idcampana
        let idlote_agri = this.siembra.idlote_agri[0].idlote_agri.idlote_agri
        this.Vact_item_siembra({ idcampana, idlote_agri });
      } else {
        this.siembradet.replantacion = null
      }
    },
    'siembradet.tiposiembra'(val) {
      console.log(val)
      if (val == false) {
        let idcampana = this.siembra.idcampana
        let idlote_agri = this.siembra.idlote_agri[0].idlote_agri.idlote_agri
        this.Vact_item_siembra({ idcampana, idlote_agri });
      } else {
        this.siembradet.replantacion = null
      }
    },
    getSenaveuno(val) {
      exportSenavePDF(val, this.data);
    },

    $route(to) {
      if (to.path === "/agregaraplicacion") {
        return this.next(0, "-");
      }
    },

    Vget_agromap_coordinates() {
      if (this.mode === "Dialog") this.initialize();
    },

    Vget_recetario_siembra(val) {
      if (val.length != 0) {
        this.recetario.datos = val[0]
      }
    },

    Vget_siembra_data_byId(val) {
      val.siembradet.forEach((x) => {
        x.total = (
          Number(x.cantidad) *
          Number(x.precio) *
          Number(x.hectarea)
        ).toFixed(4);
        x.cantxha = (Number(x.cantidad) * Number(x.hectarea)).toFixed(4);
        x.precioxha = (Number(x.cantidad) * Number(x.precio)).toFixed(4);
        if (x.iditem.idtipo_item.idtipo_item === 6) {
          x.operacion = "Aplicacion";
        } else {
          x.operacion = x.tiposiembra ? "Siembra" : "Replantacion";
        }
        if (!x.idtipo_pico) x.idtipo_pico = { idtipo_pico: "" };
        if (!x.idparcelas) x.idparcelas = { idparcelas: "" };
      });
      if (!val.idaplicador) val.idaplicador = { idaplicador: "" };
      this.siembra = JSON.parse(JSON.stringify(val));
      this.copymove.datos.origen = JSON.parse(JSON.stringify(val));
      if (this.siembra.siembrasenave.length > 0) {
        this.sen_ave = this.siembra.siembrasenave[0];
        this.senave = true;
      }
      this.Vact_recetario_siembra({ siembra: val.idsiembra })
    },
    'recetario.datos': function (newValue, oldValue) {
      console.log(newValue.length, oldValue)
      if (newValue.length != 0) {
        newValue.recetariodet.forEach((x) => {
          let total = x.cantidad * x.precio * x.hectarea
          let cantxha = x.cantidad * x.hectarea
          let precioxha = x.cantidad * x.precio
          let detail = {
            ...x,
            total: total,
            cantxha: cantxha,
            precioxha: precioxha,
            tiposiembra: true,
            idsiembra: 1,
            humedad: null,
            epi: true,
            temperatura: null,
            agua: null,
            velocidad_viento: null,
            idtipo_pico: {
              idtipo_pico: null,
            },
            idparcelas: {
              idparcelas: null,
            },
            actions: "",
          }
          this.siembra.siembradet.push(detail);
        })
      }
      if (oldValue != null) {
        for (let i = this.siembra.siembradet.length - 1; i >= 0; i--) {
          if (this.siembra.siembradet[i].hasOwnProperty("idrecetario")) {
            this.siembra.siembradet.splice(i, 1);
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("item", ["Vget_item_siembra", "Vget_item_load"]),
    ...mapGetters("recetario", ["Vget_recetario_siembra"]),
    ...mapGetters("agroreporte", ["getSenaveuno"]),
    ...mapGetters("agromap", ["Vget_agromap_coordinates"]),
    ...mapGetters("configagro", ["getConfigAgro"]),
    ...mapGetters("campana", ["Vget_campana_load"]),
    ...mapGetters("siembra", [
      "Vget_siembra_load",
      "Vget_siembra_save_load",
      "Vget_siembra_data_byId",
    ]),
    idLote() {
      if (this.siembra.idlote_agri.length === 1)
        return this.siembra.idlote_agri[0].idlote_agri.idlote_agri;
      return undefined;
    },
    configForm() {
      if (this.getConfigAgro.length > 0) {
        this.senave = this.getConfigAgro[0].senave_vdos;
        return this.getConfigAgro[0];
      }
      return "";
    },
    computedTiposiembra() {
      if (this.siembradet.tiposiembra) return "Siembra";
      return "Replantacion / Cobertura";
    },
    isMultipleLote() {
      if (this.siembra.idlote_agri.length > 1) return true;
      return false;
    },
    totalxHa() {
      let max = 0
      let total = 0
      this.siembra.siembradet.forEach((curr) => {
        if (curr.hectarea > max) {
          max = curr.hectarea;
        }
        total = total + Number(curr.total)
      });
      if (total == 0 && max == 0) return 0
      return total / max
    },
    totalgeneral() {
      return this.siembra.siembradet.reduce((acc, curr) => {
        return (acc = acc + Number(curr.total));
      }, 0);
    },
  },
  created() {
    this.initialize();
    this.fetchConfigAgro();
    const permission = getPermission(RECETARIO).permiso;
    this.permission_recetario = {
      can_view: permission[PUEDE_LISTAR]
    }
  },
  mounted() {
    const permission = getPermission(ZAFRA).permiso;
    this.permission = {
      can_delete: permission[PUEDE_ELIMINAR],
      can_reporte: permission[PUEDE_GENERAR_REPORTE],
      can_view_numbers: permission[PUEDE_VER_NUMEROS]
    };
    if (this.idsiembra != null) {
      this.Vact_siembra_data_byId(this.idsiembra);
      this.operacion = false;
    }
    setTimeout(
      () => (this.mode === "Dialog" ? this.next(2, "-") : this.next(0, "-")),
      20
    );
  },
  methods: {
    ...mapActions("item", ["Vact_item_siembra"]),
    ...mapActions("recetario", ["Vact_recetario_siembra"]),
    ...mapActions("agroreporte", ["fetchSenaveuno"]),
    ...mapActions("unidadmedida", ["Vact_unidadmedida_data_magnitud"]),
    ...mapActions("configagro", ["fetchConfigAgro"]),
    ...mapActions("siembra", [
      "Vact_siembra_save",
      "Vact_siembra_timeline",
      "Vact_siembra_data_byId",
      "Vact_siembra_update",
    ]),
    initialize() {
      this.siembradet.hectarea = this.Vget_agromap_coordinates.hectarea;
      this.siembradet_default.hectarea = this.Vget_agromap_coordinates.hectarea;
      if (this.mode === "Dialog") {
        this.siembra.idcampana = JSON.parse(JSON.stringify(this.idcampana));
        this.obtenerLoteDeCampana();
      }
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        let numb = false;
        if (input.type === "number") {
          input.type = "text";
          numb = true;
        }
        input.setSelectionRange(0, 30);
        if (numb) input.type = "number";
      }, 20);
    },
    obtenerLoteDeCampana() {
      this.idcampana.campanadet.forEach((x) => {
        if (x.idlote_agri.idlote_agri === this.propIdlote) {
          this.siembra.idlote_agri = [x];
        }
      });
    },
    changeLote(item) {
      if (item.length === 1) {
        this.siembradet_default.hectarea = item[0].idlote_agri.hectarea;
        this.siembradet.hectarea = item[0].idlote_agri.hectarea;
      }
    },
    scrollIntoView(id) {
      document.getElementById(id).scrollIntoView();
    },
    async next(id, data) {
      if (id === 9 && this.isMultipleLote) id = 10;
      if (id < 19) {
        let idTemp = id;
        let result = false;
        for (var i = 1; i < 8; i++) {
          if (document.getElementById(`siembraInput${idTemp + 1}`) !== null) {
            result = true;
            break;
          }
          idTemp = idTemp + i;
        }
        if (result) id = idTemp;
      }
      await focus.nextidName("siembraInput", id, data);
    },
    async next_senave(id, data) {
      if (id != 8) {
        await focus.nextidName("senaveInput", id, data);
      } else {
        await focus.nextidName("siembraInput", 7, data);
      }
    },
    async obtenePrecio() {
      if (!this.editDetailMode) {
        if (this.siembradet.idstock === undefined) return null;
        const calcular = {
          item: {
            valor: this.siembradet.idstock.iditem.precitem,
            cantidad_contiene: this.siembradet.idstock.iditem.cant_contiene,
            medi_unidad:
              this.siembradet.idstock.iditem.idunidadmedida.mediunidadmedida,
          },
          unidad: {
            unidad: this.siembradet.idunidadmedida.idunidadmedida,
            medi_unidad: this.siembradet.idunidadmedida.mediunidadmedida,
          },
        };
        const resp = await db.save("/apicalculoprecio/", calcular);
        setTimeout(() => (this.siembradet.precio = resp.data.data), 200);
      }
      this.editDetailMode = false;
      this.resetItem = false;
    },

    editarDetalle(val) {
      this.config.tipoItemSeleccionado =
        val.idstock.iditem.idtipo_item.idtipo_item;
      this.siembra.siembradet.splice(this.siembra.siembradet.indexOf(val), 1);
      this.siembradet = JSON.parse(JSON.stringify(val));
      if (this.configForm.aplicacion_ha_total) {
        if (this.siembradet.idstock.iditem.idtipo_item.idtipo_item == 9) {
          this.siembradet.subtotal = this.siembradet.cantidad;
        }
      }
      this.editDetailMode = true;
      if (!this.operacion) {
        this.searchItemId = val.idstock.iditem.iditem;
        setTimeout(() => (this.siembradet.precio = val.precio), 200);
      }
    },

    eliminarDetalle(val) {
      const index = this.siembra.siembradet.indexOf(val);
      this.siembra.siembradet.splice(index, 1);
    },

    limpiarCampos() {
      this.$refs.formdetalle.resetValidation();
      this.siembra = JSON.parse(JSON.stringify(this.default));
      this.siembradet = JSON.parse(JSON.stringify(this.siembradet_default));
      this.sen_ave = JSON.parse(JSON.stringify(this.sen_ave_default));
      this.siembradet.hectarea = "";
    },
    copyItems() {
      this.siembra.siembradet.map((item) => {
        item.hectarea = this.siembradet.hectarea;
      });
    },
    addTable() {
      if (
        !this.$refs.formdetalle.validate() ||
        (this.siembradet.cantidad <= 0 && this.siembradet.subtotal <= 0) ||
        this.siembradet.idstock.iditem.iditem === "" ||
        this.siembradet.precio <= 0
      )
        return null;
      if (this.siembradet.tiposiembra == false && (this.siembradet.idstock != null && this.siembradet.idstock.iditem.idtipo_item.idtipo_item == 5)) {
        if (this.siembradet.replantacion == null) {
          this.$notify(mensaje.error("Debe seleccionar sobre que va a realizar la replantacion"));
          return null;
        }
      }
      if (this.config.tipoItemSeleccionado === 10) this.siembradet.hectarea = 1;
      if (this.copyAllItems) {
        this.copyItems();
      }
      let total = 0;
      if (this.configForm.aplicacion_ha_total) {
        if (this.siembradet.idstock.iditem.idtipo_item.idtipo_item == 9) {
          total =
            Number(this.siembradet.subtotal) *
            Number(this.siembradet.precio) *
            Number(this.siembradet.hectarea);
          this.siembradet.cantidad = Number(this.siembradet.subtotal);
          this.siembradet.subtotal =
            Number(this.siembradet.subtotal) * Number(this.siembradet.hectarea);
        } else {
          total =
            Number(this.siembradet.subtotal) * Number(this.siembradet.precio);
          this.siembradet.cantidad =
            Number(this.siembradet.subtotal) / Number(this.siembradet.hectarea);
        }
      } else {
        total =
          Number(this.siembradet.cantidad) *
          Number(this.siembradet.precio) *
          Number(this.siembradet.hectarea);
        this.siembradet.subtotal =
          Number(this.siembradet.cantidad) * Number(this.siembradet.hectarea);
      }
      this.siembradet.total = total;
      this.siembradet.cantxha =
        Number(this.siembradet.cantidad) * Number(this.siembradet.hectarea);
      this.siembradet.precioxha =
        Number(this.siembradet.cantidad) * Number(this.siembradet.precio);
      this.siembra.siembradet.push(this.siembradet);
      this.siembradet = JSON.parse(JSON.stringify(this.siembradet_default));
      this.resetItem = true;
      this.Vact_unidadmedida_data_magnitud(null);
      this.$refs.formdetalle.resetValidation();
      this.next(5, "-");
    },
    isValid() {
      if (
        !this.$refs.form.validate() ||
        this.siembra.siembradet.length === 0 ||
        !this.$refs.formCabecera.validate()
      )
        return false;
      return true;
    },
    mostrarMensaje() {
      this.generar_senave.show = true;
    },
    generarSenave() {
      let siemb = this.siembra.idsiembra;
      let lot = this.siembra.idlote_agri.idlote_agri;
      let data = {
        idcampana: this.siembra.idcampana.idcampana,
        idlote: lot,
        idsiembra: siemb,
      };
      this.fetchSenaveuno(data);
    },
    async guardarSiembra() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      let a;
      this.siembra.recetario = this.recetario.datos
      if (this.operacion) {
        if (this.senave) {
          this.siembra.siembrasenave = [this.sen_ave];
        }
        a = await this.Vact_siembra_save(this.siembra);
      } else {
        let id = this.siembra.idsiembra;
        if (this.senave) {
          this.siembra.siembrasenave = [this.sen_ave];
        } else {
          this.siembra.siembrasenave = [];
        }
        a = await this.Vact_siembra_update({ id, data: this.siembra });
      }
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      if (!this.operacion) {
        this.cerrar();
      } else {
        this.$refs.form.resetValidation();
        this.$refs.formdetalle.resetValidation();
        this.$refs.formCabecera.resetValidation();
        this.limpiarCampos();
        this.cerrar();
      }
    },
    cerrar() {
      this.limpiarCampos();
      this.$emit("input", false);
      this.$emit("resp", null);
    },
    async cerrarTodo(e) {
      if (e) {
        this.cerrar();
        await this.Vact_siembra_timeline({
          campana: this.$props.idcampana,
          idlote_agri: this.$props.propIdlote,
        });
      }
    },
    async cerrarSenave() {
      this.generar_senave.show = false;
      this.cerrar();
    },
    exportToExcel() {
      exportSenave(this.getSenaveuno, this.data);
    },
    quitarRecetario() {
      this.recetario.datos = null;
    }
  },

  data() {
    return {
      humedadRules: [
        (v) => v >= 60 || "La humedad debe ser igual o mayor a 60",
      ],
      temperaturaRules: [
        (v) => v <= 30 || "La temperatura debe ser igual o menor a 30",
      ],
      velocidadRules: [
        (v) => v <= 10 || "La velocidad del viento debe ser igual o menor a 10",
      ],
      resetItem: false,
      copymove: {
        show: false,
        datos: {
          origen: {},
          destino: {},
        },
      },
      recetario: {
        show: false,
        datos: null,
      },
      generar_senave: {
        show: false,
        datos: {},
      },
      editDetailMode: false,
      searchItemId: null,
      copyAllItems: false,
      operacion: true,
      config: {
        tipoItemSeleccionado: 5,
      },
      dialog: {
        delete: false,
      },
      permission: {
        can_delete: false,
        can_reporte: false,
        can_view_numbers: false,
      },
      sen_ave: {
        epi: true,
        humedad: 0,
        temperatura: 0,
        agua: 0,
        velocidad_viento: 0,
        idtipo_pico: {
          idtipo_pico: null,
        },
        idlote_ing: {
          idlote_ing: null,
        },
        idaplicador: {
          idaplicador: null,
        },
      },
      sen_ave_default: {
        epi: true,
        humedad: 0,
        temperatura: 0,
        agua: 0,
        velocidad_viento: 0,
        idtipo_pico: {
          idtipo_pico: null,
        },
        idlote_ing: {
          idlote_ing: null,
        },
        idaplicador: {
          idaplicador: null,
        },
      },
      senave: false,
      siembra: {
        idsiembra: 0,
        idcampana: {
          campanadet: [],
        },
        idaplicador: {
          idaplicador: "",
        },
        fecha: current_date(),
        fechafin: current_date(),
        comentario: "",
        estado: true,
        siembradet: [],
        siembrasenave: [],
        idlote_agri: "",
      },
      default: {
        idsiembra: 0,
        idcampana: {
          campanadet: [],
        },
        idaplicador: {
          idaplicador: "",
        },
        fecha: current_date(),
        fechafin: current_date(),
        comentario: "",
        estado: true,
        siembradet: [],
        siembrasenave: [],
        idlote_agri: "",
      },

      siembradet: {
        idsiembra: 1,
        idstock: undefined,
        idunidadmedida: {
          idunidadmedida: "",
        },
        tiposiembra: true,
        cantidad: "",
        subtotal: "",
        precio: "",
        hectarea: "",
        idcampana: "",
        humedad: null,
        epi: true,
        temperatura: null,
        agua: null,
        velocidad_viento: null,
        idtipo_pico: {
          idtipo_pico: null,
        },
        idparcelas: {
          idparcelas: null,
        },
        replantacion: null,
        actions: "",
      },
      siembradet_default: {
        idsiembra: 1,
        idstock: undefined,
        idunidadmedida: {
          idunidadmedida: "",
        },
        tiposiembra: true,
        cantidad: "",
        subtotal: "",
        precio: "",
        hectarea: "",
        idcampana: "",
        humedad: null,
        epi: true,
        temperatura: null,
        agua: null,
        velocidad_viento: null,
        idtipo_pico: {
          idtipo_pico: null,
        },
        idparcelas: {
          idparcelas: null,
        },
        replantacion: null,
        actions: "",
      },
      data: {
        idcampana: {
          idcampana: "",
        },
        asesor: "",
        matricula: "",
        regsenave: "",
      },
      permission_recetario: {
        can_view: false,
      }
    };
  },
};
</script>
