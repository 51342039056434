export default {
  estadopedido_data(state, request) {
    state.estadopedido_data = request;
  }, 
  estadopedido_byId(state, request) {
    state.estadopedido_byId = request;
  }, 
  estadopedido_load(state, request) {
    state.estadopedido_load = request;
  }, 
  estadopedido_save_load(state, request) {
    state.estadopedido_save_load = request;
  }, 
}