import { get, post, put, del } from "@/services/api/api.service";
export default {
    async Vact_vehiculo_data({ commit }) {
        commit('vehiculo_load', true)
        try {
            const data = await get('/apivehiculo/')
            commit('vehiculo_data', data)
            commit('vehiculo_load', false)
        } catch (e) {
            commit('vehiculo_load', false)
            return e
        }
    },
    async Vact_vehiculo_save({ commit, dispatch }, data) {
        commit('vehiculo_load', true)
        try {
            const resp = await post('/apivehiculo/', data)
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('vehiculo_load', false)
            return resp
        } catch (e) {
            commit('vehiculo_load', false)
            return e
        }
    },
    async Vact_vehiculo_update({ commit, dispatch }, { id, data }){
        commit('vehiculo_load',true);
        try {
            const resp = await put('/apivehiculo/'+id+'/', data);
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('vehiculo_load', false);
            return resp;
        } catch (e) {
            commit('vehiculo_load', false);
            return e
        } 
    },
    async Vact_vehiculo_delete({ dispatch }, id){
        try {
            const data = await del('/apivehiculo/'+id);
            dispatch('snackbar/setNotification', data, { root: true })
            return data
        } catch (e) {
            return false
        } 
    },
}
