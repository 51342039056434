<template>
    <div>
        <v-progress-linear :active="Vget_compra_load"></v-progress-linear>
        <v-list subheader two-line>
            <v-subheader inset>Elija una compra</v-subheader>
            <v-list-item v-for="(i, index) of Vget_compra_search" :key="index">
                <v-list-item-content>
                    <v-list-item-title v-text="i.idcontacto.idcontacto.desccontacto"></v-list-item-title>
                    <v-list-item-subtitle v-text="`${i.regi_compra} - ${i.numfcompra}`"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="$emit('selectedCompra', i)">
                        <v-icon color="info">arrow_forward_ios</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {
        idproveedor: {
            default: null
        }
    },
    computed: {
        ...mapGetters("compra", ["Vget_compra_search", "Vget_compra_load"]),
    },
    watch: {
        idproveedor(val) {
            this.busqueda(val)
        }
    },
    created() {
        this.busqueda(this.idproveedor)
    },
    methods: {
        ...mapActions("compra", ["Vact_compra_search"]),
        async busqueda(val) {
            try {
                if (val.idcontacto != '') {
                    this.Vact_compra_search({ contacto: val.idcontacto })
                } else {
                    this.Vact_compra_search({ contacto: 0 })
                }
                
            } catch (e) {
                this.Vact_compra_search({ contacto: 0 })
            }
        }
    },
    data() {
        return {
        }
    }
}
</script>