import jsPDF from "jspdf"
import "jspdf-autotable"
import { money } from "@/services/moneyconvert";
import { current_date, dmy } from "@/services/util/date.service"
//import { currency } from "@/services/util/number.service";
//import { horasFormat } from "@/services/util/date.service";

export const generarRecibo01 = (data) => {
    let doc = new jsPDF()
    let pos = 0
    for (let step = 0; step < 2; step++) {
        doc.setFontSize(12)
        doc.text(data.title.empresa, 15, pos + 10)
        doc.setFontSize(8)
        doc.text(`Dirección: ${data.title.direccion}`, 15, pos + 15)
        doc.text(`Tel: ${data.title.telefono}`, 15, pos + 20)
        doc.setDrawColor(171, 178, 185)
        doc.line(15, pos + 22, doc.internal.pageSize.width - 15, pos + 22)
        doc.setFontSize(9)
        doc.text("RECIBO DE DINERO", 105, pos + 27, null, null, "center")
        let bod = [
            [
                "Transacción",
                data.title.transaccion,
                "Fecha",
                data.title.fecha
            ],
            [
                "Funcionario",
                `${data.title.funcionario.nombre} ${data.title.funcionario.apellido}`,
                "Operación",
                "Salario"
            ],
            [
                "Periodo de pago",
                data.title.periodo,
                "",
                ""
            ]
        ]
        doc.autoTable({
            body: bod,
            startY: pos + 30,
            theme: 'plain',
            styles: {
                cellPadding: 1,
                fontSize: 9
            },
            columnStyles: {
                0: { cellWidth: 40, halign: "right" },
                2: { cellWidth: 40, halign: "right" },
            },
            didParseCell: function (data) {
                if (data.column.index === 0 || data.column.index === 2) {
                    data.cell.styles.fontStyle = "bold";
                }
            },
        })
        doc.autoTable({
            head: [
                ["Código", "Descripción", "Referencia", "Debe", "Haber"]
            ],
            body: data.detalle,
            startY: doc.lastAutoTable.finalY + 2,
            theme: "plain",
            styles: {
                cellPadding: 1,
                fontSize: 9
            },
            headStyles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1
            },
            columnStyles: {
                3: { halign: "right" },
                4: { halign: "right" }
            },
            didParseCell: function (data) {
                if (data.row.index === data.table.body.length - 1) {
                    data.cell.styles.fontStyle = "bold"; // Negrita en la última fila
                    data.cell.styles.lineWidth = { top: 0.1, right: 0, bottom: 0, left: 0 };
                }
                data.cell.styles.lineColor = [0, 0, 0];
            },
        })
        doc.autoTable({
            body: [
                [
                    `Recibí en ${data.moneda.plural}:`, data.diferencia
                ],
                [
                    `${money.letter(data.monto, null)}.-`, ""
                ],
                [
                    "", `..........................................................`
                ],
                [
                    "", data.title.funcionario.idcontacto.ruccontacto
                ],
                [
                    "", `${data.title.funcionario.nombre} ${data.title.funcionario.apellido}`
                ]
            ],
            startY: doc.lastAutoTable.finalY + 2,
            theme: "plain",
            styles: {
                cellPadding: 1,
                fontSize: 9
            },
            didParseCell: function (data) {
                if (data.column.index === 1 && data.row.index === 0) {
                    data.cell.styles.halign = "right"
                    data.cell.styles.fontStyle = "bold"
                } else if (data.column.index === 1 && data.row.index !== 0) {
                    data.cell.styles.halign = "center"
                }
            }
        })
        pos = doc.lastAutoTable.finalY + 10
    }

    doc.save(
        `${data.title.funcionario.nombre} ${data.title.funcionario.apellido} ${dmy(current_date())}.pdf`
    )
}