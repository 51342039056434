<template>
    <div>
        <Configuracion v-if="configurar" v-model="configurar" />
        <v-alert type="error" v-if="!permission.reporte">Error!!! Este usuario no tiene permisos en esta
            ventana</v-alert>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-text-field autocomplete="off" :rules="validar" hide-details single-line label="Año"
                    v-model="data.year" class="mr-2 mt-2"></v-text-field>
                <v-autocomplete :items="meses" item-text="text" item-value="id" :rules="validar" autocomplete="off"
                    hide-details single-line label="Mes" v-model="data.month" class="mr-2 mt-2"></v-autocomplete>
                <v-text-field autocomplete="off" :rules="validar" hide-details single-line label="Dia"
                    v-model="data.day" class="mr-2 mt-2"></v-text-field>
                <c-btn-table-reload @click="obtenerPago()" v-if="permission.reporte" class="mr-2"></c-btn-table-reload>
                <c-btn-table-download @click="descargarPago()" v-if="permission.reporte" class="mr-2"></c-btn-table-download>
                <c-btn-table-download @click="descargarPago2()" v-if="permission.reporte" class="mr-2"></c-btn-table-download>
                <c-btn-table-change @click="configurar = true"></c-btn-table-change>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :headers="headers" :items="getArchivoPagoConfig" :loading="isArchivoPagoConfigLoading">
        </v-data-table>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PAGO_SALARIO } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
} from "@/constants/permissions";
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
import Configuracion from "./Configuracion.vue";
import { trueGETBlob } from "@/services/api/api.service";
export default {
    components: {
        Configuracion
    },
    data() {
        return {
            validar: [(v) => !!v || "Seleccion un campo"],
            permission: {
                reporte: false,
            },
            configurar: false,
            data: {
                year: 2025,
                month: 1,
                day: 1,
            },
            consulta: {
                desde: null,
                hasta: null
            },
            meses: [
                {
                    id: 1,
                    text: "Enero"
                },
                {
                    id: 2,
                    text: "Febrero"
                },
                {
                    id: 3,
                    text: "Marzo"
                },
                {
                    id: 4,
                    text: "Abril"
                },
                {
                    id: 5,
                    text: "Mayo"
                },
                {
                    id: 6,
                    text: "Junio"
                },
                {
                    id: 7,
                    text: "Julio"
                },
                {
                    id: 8,
                    text: "Agosto"
                },
                {
                    id: 9,
                    text: "Septiembre"
                },
                {
                    id: 10,
                    text: "Octubre"
                },
                {
                    id: 11,
                    text: "Noviembre"
                },
                {
                    id: 12,
                    text: "Diciembre"
                },
            ],
            headers: []
        }
    },
    computed: {
        ...mapGetters("salario", ["getArchivoPagoConfig", "isArchivoPagoConfigLoading"]),
    },
    watch: {
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        const permiso = getPermission(PAGO_SALARIO).permiso
        this.permission.reporte = permiso[PUEDE_AGREGAR]
    },
    mounted() {
    },
    methods: {
        ...mapActions("salario", ["fetchArchivoPagoConfig"]),
        obtenerPago() {
            this.fetchArchivoPagoConfig(this.data)
        },
        async descargarPago2() {
            
            await trueGETBlob("/apigetarchivopago/", this.data);
        },
        toCurrency(value) {
            return currency(value);
        },
    }
}
</script>