<template>
    <div>
        <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
    <Crud v-if="crud.view" v-model="crud.view" :props="crud.datos" editable @datos="crud.datos = $event" />
        <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
        <v-toolbar flat dense>
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <c-text-table-search v-if="permission.can_view" v-model="search" class="mr-1"></c-text-table-search>
            <v-toolbar-items>
                <c-btn-table-add @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                <c-btn-table-reload class="ml-1" @click="Vact_vehiculo_data()"></c-btn-table-reload>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :headers="headers" :items="Vget_vehiculo_data" :search="search" :loading="Vget_vehiculo_load"
            v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
                <c-btn-table-edit @click="update_item(props.item)" />
                <c-btn-table-delete @click="delete_item(props.item)" />
            </template>
        </v-data-table>

        <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getPermission } from '@/services/storage/permissionStorage.service'
import { mensaje } from '@/services/notify.service'
import Crud from './Crud'
import Delete from './Delete'
import { VEHICULO } from '@/constants/forms'
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR
} from '@/constants/permissions'
export default {
    components: {
        Crud,
        Delete,
    },
    data() {
        return {
            title: VEHICULO,
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idvehiculo" },
                { text: "Descripcion", align: "left", value: "descripcion" },
                { text: "Chapa", align: "left", value: "chapa" },
                { text: "Accion", value: "accion", sortable: false, align: "right" }
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
            crud: {
                delete: false,
                add: false,
                view: false,
                datos: null,
            },
        }
    },
    computed: {
        ...mapGetters("vehiculo", ["Vget_vehiculo_data", "Vget_vehiculo_load"]),
        permission_alert() {
            return mensaje.permission(this.permission)
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        const permission = getPermission(VEHICULO).permiso
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR]
        }
        this.Vact_vehiculo_data()
    },
    methods: {
        ...mapActions("vehiculo", ["Vact_vehiculo_data"]),
        update_item(data) {
            this.crud.datos = JSON.parse(JSON.stringify(data));
            this.crud.view = true;
        },
        delete_item(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.delete = true;
        },
    }
}
</script>