
export default {
  getSalario: (state) => {
    return state.salario
  },
  getArchivoPago: (state) => {
    return state.archivoPago
  },
  getArchivoPagoConfig: (state) => {
    return state.archivoPagoConfig
  },
  isArchivoPagoConfigLoading: (state) => {
    return state.isArchivoPagoConfigLoading
  },
  isArchivoPagoLoading: (state) => {
    return state.isArchivoPagoLoading
  },
  isSalarioLoading: (state) => {
    return state.isLoading
  },
  getSalarioIntermediate: (state) => {
    return state.salarioIntermediate
  }
}
