import { db } from "@/services/db.service";

export default {
    async Vact_estadopedido_data({ commit }) {
        commit('estadopedido_load', true);
        try {
            let data = await db.get('/apiestadopedido/');
            commit('estadopedido_data', data)
        } catch (e) {
            commit('estadopedido_save_load', false);
            return e
        }
        commit('estadopedido_load', false);
    },
    async Vact_estadopedido_byId({ commit }, id) {
        commit('estadopedido_load', true);
        commit('estadopedido_data', null);
        try {
            const data = await db.get('/apiestadopedido/' + id + '/');
            commit('estadopedido_byId', data);
            commit('estadopedido_load', false);
        } catch (e) {
            commit('estadopedido_load', false);
            return e
        }
    },
    async Vact_estadopedido_del({ dispatch }, id) {
        try {
            const data = await db.delete('/apiestadopedido/' + id + '/');
            dispatch('Vact_estadopedido_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_estadopedido_save({ commit }, data) {
        commit('estadopedido_save_load', true);
        try {
            const resp = await db.save('/apiestadopedido/', data);
            commit('estadopedido_save_load', false);
            return resp;
        } catch (e) {
            commit('estadopedido_save_load', false);
            return e
        }
    },
    async Vact_estadopedido_update({ commit }, { id, data }) {
        commit('estadopedido_save_load', true);
        try {
            const resp = await db.update('/apiestadopedido/' + id + '/', data);
            commit('estadopedido_save_load', false);
            return resp;
        } catch (e) {
            commit('estadopedido_save_load', false);
            return e
        }
    }
}
