export default [
  {
    path: "/agricultura",
    component: () =>
      import(/* webpackChunkName: "Agricultura" */ "@/views/Agro/Root.vue"),
    children: [
      {
        path: "campo",
        component: () =>
          import(/* webpackChunkName: "Agro" */ "@/views/Agro/Agro.vue"),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "List" */ "@/views/Agro/Campo/List.vue"
              ),
          },
          {
            path: "plagas",
            component: () =>
              import(
                /* webpackChunkName: "List" */ "@/views/Agro/Plagas/Root.vue"
              ),
            children: [
              {
                path: "/",
                name: "Historial de Plagas",
                component: () =>
                  import(
                    /* webpackChunkName: "List" */ "@/views/Agro/Plagas/List.vue"
                  ),
              },
              {
                path: "add",
                name: "Registrar Plaga",
                component: () =>
                  import(
                    /* webpackChunkName: "List" */ "@/views/Agro/Plagas/Form.vue"
                  ),
              },
              {
                path: "edit/:id",
                name: "Modificar Plaga",
                component: () =>
                  import(
                    /* webpackChunkName: "List" */ "@/views/Agro/Plagas/Form.vue"
                  ),
              },
            ],
          },
          {
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Add" */ "@/views/Agro/Campo/Add.vue"
              ),
          },
          {
            path: "edit/:id",
            component: () =>
              import(
                /* webpackChunkName: "Add" */ "@/views/Agro/Campo/Edit.vue"
              ),
          },
          {
            path: "detalle/:id",
            name: "Agro Detalle",
            component: () =>
              import(
                /* webpackChunkName: "Detalle" */ "@/views/Agro/Campo/Detail.vue"
              ),
          },
        ],
      },
      {
        path: "sensor",

        component: () =>
          import(
            /* webpackChunkName: "Route" */ "@/views/Agro/Sensor/Root.vue"
          ),
        children: [
          {
            path: "",
            name: "Sensores",
            component: () =>
              import(
                /* webpackChunkName: "Sensor" */ "@/views/Agro/Sensor/Sensor.vue"
              ),
          },
          {
            path: ":id",
            name: "Datos",
            component: () =>
              import(
                /* webpackChunkName: "Sensor" */ "@/views/Agro/Sensor/Datos.vue"
              ),
          },
        ],
      },
      {
        path: "campana",
        name: "Campaña",
        component: () =>
          import(
            /* webpackChunkName: "Campana" */ "@/views/Agro/Campana/Campana.vue"
          ),
      },
      {
        path: "transaccion",
        component: () =>
          import(
            /* webpackChunkName: "Transaccion" */ "@/views/Agro/Transaccion/Root.vue"
          ),
        children: [
          {
            path: "insumos",
            name: "Insumo",
            component: () =>
              import(
                /* webpackChunkName: "Egreso" */ "@/views/Agro/Transaccion/Egreso/Egreso.vue"
              ),
          },
          {
            path: "ventagrano",
            name: "Venta de Granos",
            component: () =>
              import(
                /* webpackChunkName: "Egreso" */ "@/views/Agro/Transaccion/Venta/Venta.vue"
              ),
          },
          {
            path: "preventagrano",
            name: "Preventa de Granos",
            component: () =>
              import(
                /* webpackChunkName: "Egreso" */ "@/views/Agro/Transaccion/Preventa/Preventa.vue"
              ),
          },
          {
            path: "canje",
            name: "Canje de Granos",
            component: () =>
              import(
                /* webpackChunkName: "Canje" */ "@/views/Agro/Transaccion/Canje/Canje.vue"
              ),
          },
          {
            path: "canjesimple",
            name: "Canje simple de Granos",
            component: () =>
              import(
                /* webpackChunkName: "Canje Simple" */ "@/views/Agro/Transaccion/CanjeSimple/CanjeSimple.vue"
              ),
          },
          {
            path: "recetario",
            name: "Recetario",
            component: () =>
              import(
                /* webpackChunkName: "Recetario" */ "@/views/Agro/Transaccion/Recetario/Recetario.vue"
              ),
          },
          {
            path: "reajuste",
            name: "Reajuste",
            component: () => 
              import(
                /* webpackChunkName: "Reajuste" */ "@/views/Agro/Transaccion/Reajuste/Reajuste.vue"
              )
          }
          

        ],
      },
      {
        path: "report",
        component: () =>
          import(
            /* webpackChunkName: "Fijacion" */ "@/views/Agro/Reporte/Root.vue"
          ),
        children: [
          {
            path: "/",
            name: "Reporte Avanzado",
            component: () =>
              import(
                /* webpackChunkName: "Avanzado" */ "@/views/Agro/Reporte/Avanzado2/Avanzado.vue"
              ),
          },
          {
            path: "lote",
            name: "Reporte por Lote",
            component: () =>
              import(
                /* webpackChunkName: "Lote" */ "@/views/Agro/Reporte/Campo.vue"
              ),
          },
          {
            path: "index",
            name: "Indice Historico",
            component: () =>
              import(
                /* webpackChunkName: "Avanzado" */ "@/views/Agro/Reporte/IndiceHistorico.vue"
              ),
          },
          {
            path: "costos",
            name: "Estimacion de Costos",
            component: () =>
              import(
                /* webpackChunkName: "Avanzado" */ "@/views/Agro/Reporte/EstimacionCosto.vue"
              ),
          },
          {
            path: "senave",
            name: "Reporte Senave",
            component: () =>
              import(
                /* webpackChunkName: "Senave" */ "@/views/Agro/Reporte/Senave/Senave.vue"
              ),
          },
          {
            path: "calendario",
            name: "Calendario",
            component: () =>
              import(
                /* webpackChunkName: "Senave" */ "@/views/Agro/Reporte/Calendario/Calendario.vue"
              ),
          },
          {
            path: "indicelote",
            name: "Indice Lote",
            component: () =>
              import(
                /* webpackChunkName: "Senave" */ "@/views/Agro/Reporte/IndiceLote/IndiceLote.vue"
              ),
          },
          {
            path: "ultimaaplicacion",
            name: "Ultima Aplicacion",
            component: () =>
              import(
                /* webpackChunkName: "Senave" */ "@/views/Agro/Reporte/UltimaAplicacion/UltimaAplicacion.vue"
              ),
          },
          {
            path: "todasaplicaciones",
            name: "Todas las Aplicaciones",
            component: () =>
              import(
                /* webpackChunkName: "Senave" */ "@/views/Agro/Reporte/UltimaAplicacion/TodasAplicaciones.vue"
              ),
          }
        ],
      },
      {
        path: "mantenimiento",
        component: () =>
          import(
            /* webpackChunkName: "Mantenimiento" */ "@/views/Agro/Mantenimiento/Root.vue"
          ),
        children: [
          {
            path: "lotegrupo",
            name: "Lote Grupo",
            component: () =>
              import(
                /* webpackChunkName: "Lote Grupo" */ "@/views/Agro/Mantenimiento/Lotegrupo/Lotegrupo.vue"
              ),
          },
          {
            path: "tipopico",
            name: "Tipo Pico",
            component: () =>
              import(
                /* webpackChunkName: "Tipo Pico" */ "@/views/Agro/Mantenimiento/TipoPico/TipoPico.vue"
              ),
          },
          {
            path: "aplicador",
            name: "Aplicador",
            component: () =>
              import(
                /* webpackChunkName: "Tipo Pico" */ "@/views/Agro/Mantenimiento/Aplicador/Aplicador.vue"
              ),
          },
          {
            path: "parcelas",
            name: "Parcelas",
            component: () =>
              import(
                /* webpackChunkName: "Tipo Pico" */ "@/views/Agro/Mantenimiento/Parcela/Parcela.vue"
              ),
          },
          {
            path: "Ingenieros",
            name: "Ingenieros",
            component: () =>
              import(
                /* webpackChunkName: "Ingenieros" */ "@/views/Agro/Mantenimiento/LoteIngeniero/LoteIngeniero.vue"
              ),
          },
          {
            path: "Validacion",
            name: "Validacion",
            component: () => 
              import(
                /* webpackChunkName: "Validacion" */ "@/views/Agro/Mantenimiento/Validacion/Validacion.vue"
              ),
          }
        ],
      },
      {
        path: "settings",
        name: "Configuraciones Agro",
        component: () =>
          import(
            /* webpackChunkName: "Campana" */ "@/views/Agro/Configuracion/Configuracion.vue"
          ),
      },
    ],
  },
  {
    path: "/agregaraplicacion",
    name: "Siembra/Aplicacion",
    component: () =>
      import(
        /* webpackChunkName: "Siembra/Aplicacion" */ "@/views/Agro/Siembra/Content.vue"
      ),
  },
];
