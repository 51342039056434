<template>
    <v-dialog persistent max-width="500" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" @submit.prevent="next(1, data.descmotivotraslado)">
                    <v-row dense>
                        <v-col cols="12">
                            <c-text-field label="Descripcion" ref="motivo1" v-model="data.descmotivotraslado"
                                placeholder="Ingrese una descripcion"
                                @keyup.native.enter="next(1, data.descmotivotraslado)"></c-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="motivo2" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="Vget_motivotraslado_load"></c-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null
        },
        crudMode: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.editable) this.data = JSON.parse(JSON.stringify(this.props))
        if (this.syncInput != null) {
            this.data.descmotivotraslado = this.syncInput
            this.sync = this.syncInput
        }
        setTimeout(() => this.next(0, "-"), 20)
    },
    computed: {
        ...mapGetters("motivotraslado", ["Vget_motivotraslado_load"]),
        title() {
            return this.editable ? "Modificar Motivo Traslado" : "Registrar Motivo Traslado"
        }
    },
    methods: {
        ...mapActions("motivotraslado", ["Vact_motivotraslado_save", "Vact_motivotraslado_update", "Vact_motivotraslado_data"]),
        cancel() {
            this.$emit("input", false)
        },
        next(id, data) {
            if (data != "") {
                try {
                    this.$refs[`motivo${id + 1}`].focus()
                } catch (error) {
                    this.$refs[`motivo${id + 1}`].$el.focus()
                }
            }
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            const response = this.editable
                ? await this.Vact_motivotraslado_update({
                    data: this.data,
                    id: this.data.idmotivotraslado
                })
                : await this.Vact_motivotraslado_save(this.data)
            if (response.success) {
                this.data = JSON.parse(JSON.stringify(this.default))
                this.$refs.form.resetValidation()
                this.Vact_motivotraslado_data()
                if (this.sync != null) {
                    if (this.crudMode == true) {
                        this.$emit("sync-input", response.data.id)
                        return this.cancel()
                    }
                }
                this.next(0, "-")
                if (this.editable) this.cancel()
            }
        }
    },
    data: () => ({
        data: {
            idmotivotraslado: 0,
            descmotivotraslado: ""
        },
        default: {
            idmotivotraslado: 0,
            descmotivotraslado: ""
        },
        sync: null,
    })
}
</script>