
export default {

  Vget_compra_data(state) {
    return state.compra_data;
  },
  Vget_compra_search(state) {
    return state.compra_search;
  },
  Vget_compra_byId(state) {
    return state.compra_data_id;
  },
  Vget_compra_load(state) {
    return state.compra_load;
  },
  Vget_compra_save_load(state) {
    return state.compra_save_load ;
  },
}
