import { get, post, put, del } from "@/services/api/api.service";

export default {
    async Vact_notaremision_data({ commit }, { desc = null, limit = null, contacto = null }) {
        commit('notaremision_load', true);
        try {
            let data = ''
            if (contacto) {
                data = await get(`/apinotaremision/?contacto=${contacto}`)
                commit('notaremision_search', data)
            } else if (desc) {
                data = await get(`/apinotaremision/?desc=${desc}`);
                commit('notaremision_data', data)
            } else {
                data = await get(`/apinotaremision/?limit=${limit}`);
                commit('notaremision_data', data)
            }
            
        } catch (e) {
            commit('notaremision_save_load', false);
            return e
        }
        commit('notaremision_load', false);
    },
    async Vact_notaremision_pendiente({ commit }, { tipo, id }) {
        commit('notaremision_pendiente', [])
        try {
            const data = await get(`/apinotaremisionpendiente/?tipo=${tipo}&id=${id}`)
            commit('notaremision_pendiente', data)
            return data
        } catch (e) {
            return e
        }
    },
    async Vact_notaremision_reporte({ commit }, { contacto, inicio, fin }) {
        commit('notaremision_reporte', [])
        try {
            const data = await get(`/apinotaremisionreporte/?contacto=${contacto}&inicio=${inicio}&fin=${fin}`)
            commit(`notaremision_reporte`, data)
            return data
        } catch (e) {
            return e
        }
    },
    async Vact_notaremision_byId({ commit }, id) {
        commit('notaremision_load', true);
        try {
            const data = await get('/apinotaremision/' + id + '/');
            commit('notaremision_byId', data);
            commit('notaremision_load', false);
        } catch (e) {
            commit('notaremision_load', false);
            return e
        }
    },
    async Vact_notaremision_del({ dispatch }, id) {
        try {
            const data = await del('/apinotaremision/' + id + '/');
            dispatch('snackbar/setNotification', data, { root: true })
            return data
        } catch (e) {
            return e
        }
    },
    async Vact_notaremision_save({ commit, dispatch }, data) {
        commit('notaremision_save_load', true);
        try {
            const resp = await post('/apinotaremision/', data);
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('notaremision_save_load', false);
            return resp;
        } catch (e) {
            commit('notaremision_save_load', false);
            return e
        }
    },
    async Vact_notaremision_update({ commit, dispatch }, { id, data }) {
        commit('notaremision_save_load', true);
        try {
            const resp = await put('/apinotaremision/' + id + '/', data);
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('notaremision_save_load', false);
            return resp;
        } catch (e) {
            commit('notaremision_save_load', false);
            return e
        }
    }
}
