import jsPDF from "jspdf"
import "jspdf-autotable"
import { current_date, dmy, unixDiaMesAno } from "@/services/util/date.service"
//import { money } from "@/services/moneyconvert"


const urlImageBase64 = async (url) => {
    const respuesta = await fetch(url);
    const blob = await respuesta.blob();

    return new Promise((resolve) => {
        const lector = new FileReader();
        lector.onloadend = () => resolve(lector.result);
        lector.readAsDataURL(blob);
    });
}


export const generarRecibo02 = async (reg) => {
    let doc = new jsPDF()
    let pos = 0
    for (let step = 0; step < 2; step++) {
        doc.setFontSize(12)
        doc.setDrawColor(0)
        doc.setFillColor(255, 255, 255)
        
        doc.roundedRect(8, pos + 10, 120, 20, 5, 5, "FD")
        doc.roundedRect(130, pos + 10, 60, 20, 5, 5, "FD")
        doc.roundedRect(8, pos + 32, 182, 20, 5, 5, "FD")

        await urlImageBase64('https://server.integral.com.py/media/logointegral.png').then(base64 => {
            doc.addImage(base64, 10, pos + 12, 47, 15)
        });

        doc.autoTable({
            startY: pos + 12, // Dentro del primer cuadro
            body: [[`Tel: ${reg.title.telefono}`], [`${reg.title.direccion}`]],
            theme: "plain",
            styles: {
                cellPadding: 1,
                fontSize: 9,
            },
            margin: { left: 55 },
            tableWidth: 70,
        });

        doc.autoTable({
            startY: pos + 12, // Dentro del segundo cuadro
            body: [["RECIBO DE SALARIO"], [`N° ${reg.title.transaccion}`]],
            theme: "plain",
            margin: { left: 132 },
            styles: {
                cellPadding: 1,
                fontSize: 9,
            },
            columnStyles: {
                0: { halign: "center" }
            },
            tableWidth: 56,
        });

        doc.autoTable({
            startY: pos + 34, // Dentro del tercer cuadro
            body: [["Fecha", reg.title.fecha, "Sucursal", reg.title.sucursal], ["Funcionario", `${reg.title.funcionario.nombre} ${reg.title.funcionario.apellido}`, "Sector", "Sector 1"], ["Período de Pago", reg.title.periodo, "Operación", "Salario"]],
            theme: "plain",
            margin: { left: 10 },
            styles: {
                cellPadding: 1,
                fontSize: 9,
            },
            columnStyles: {
                0: { cellWidth: 35, halign: "left", fontStyle: "bold" },
                2: { cellWidth: 35, halign: "left", fontStyle: "bold" },
            },
            tableWidth: 178,
        });

        doc.autoTable({
            head: [
                ["Código", "Descripción", "Referencia", "Debe", "Haber"]
            ],
            body: reg.detalle,
            startY: pos + 54,
            theme: "plain",
            margin: { left: 8, right: 20 },
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.1,
            styles: {
                cellPadding: 1,
                fontSize: 9,
            },
            headStyles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
            },
            columnStyles: {
                3: { halign: "right", },
                4: { halign: "right", }
            },
            didParseCell: function (data) {
                data.cell.styles.lineColor = 'black'
                if (data.row.index >= 0 && data.row.section === "body") {
                    if (data.row.index >= data.table.body.length - 2) {
                        data.cell.styles.lineWidth = { left: 0.1, top: 0.1 }
                        if (data.column.index === 0) {
                            data.cell.colSpan = 3;
                            data.cell.rowSpan = 2;
                        } else {
                            data.cell.styles.fontStyle = "bold";
                        }
                    } else {
                        data.cell.styles.lineWidth = { left: 0.1 }
                    }
                }
            }
        })
        let texto = `Recibí de ${reg.title.empresa} la suma de ${reg.moneda.abreviatura} ${reg.diferencia} en concepto de sueldo correspondiente al período indicado más arriba, siendo este instrumento suficiente recibo y carta de pago por la cantidad expresada, firmandose en dos ejemplares de un mismo tenor y a un solo efecto el ${unixDiaMesAno(reg.title.fecha_)}`
        doc.autoTable({
            theme: "plain",
            margin: { left: 8, right: 20 },
            body: [
                [texto, "......................................................"],
                ["", reg.title.funcionario.idcontacto.ruccontacto],
                ["", `${reg.title.funcionario.nombre} ${reg.title.funcionario.apellido}`]
            ],
            styles: {
                cellPadding: 1,
                fontSize: 9
            },
            columnStyles: {
                0: { halign: "justify" },
                1: { halign: "center", },
            },
            didParseCell: function (data) {
                if (data.column.index === 0) {
                    data.cell.rowSpan = 3;
                }
            }
        })
        pos = doc.lastAutoTable.finalY + 4
    }
    doc.save(
        `${reg.title.funcionario.nombre} ${reg.title.funcionario.apellido} ${dmy(current_date())}.pdf`
    )
}