<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="Vget_vehiculo_load"
    >Desea eliminar el vehiculo <strong>{{ props.descripcion }}</strong></c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("vehiculo", ["Vget_vehiculo_load"]),
    },
    watch: {
        $route() {
            return this.close()
        },
    },
    methods: {
        ...mapActions("vehiculo", ["Vact_vehiculo_delete", "Vact_vehiculo_data"]),
        async save() {
            const id = this.props.idvehiculo
            const response = await this.Vact_vehiculo_delete(id)
            if (!response.success) return null
            this.close()
        },
        close() {
            this.$emit("datos", null)
            this.$emit("input", false)
            this.Vact_vehiculo_data()
        }
    }
}
</script>