import {SET_LOADING, SET_SALARIO, SET_LOADING_ARCHIVO_PAGO, SET_ARCHIVO_PAGO, SET_LOADING_ARCHIVO_PAGO_CONFIG, SET_ARCHIVO_PAGO_CONFIG, SET_SALARIO_INTERMEDIATE} from './constants'
export default {
  [SET_SALARIO](state, request) {
    state.salario = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
  [SET_LOADING_ARCHIVO_PAGO](state, request) {
    state.isArchivoPagoLoading = request;
  },
  [SET_ARCHIVO_PAGO](state, request) {
    state.archivoPago = request;
  },
  [SET_LOADING_ARCHIVO_PAGO_CONFIG](state, request) {
    state.isArchivoPagoConfigLoading = request;
  },
  [SET_ARCHIVO_PAGO_CONFIG](state, request) {
    state.archivoPagoConfig = request;
  },
  [SET_SALARIO_INTERMEDIATE](state, request) {
    state.salarioIntermediate = request
  }
}