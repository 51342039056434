export default {
  pedidocompra_data(state, request) {
    state.pedidocompra_data = request;
  },
  pedidocompra_search(state, request) {
    state.pedidocompra_search = request;
  }, 
  pedidocompra_byId(state, request) {
    state.pedidocompra_byId = request;
  }, 
  pedidocompra_load(state, request) {
    state.pedidocompra_load = request;
  }, 
  pedidocompra_save_load(state, request) {
    state.pedidocompra_save_load = request;
  }, 
}