
export default {
  Vget_pedidocompra_data: (state) => {
    return state.pedidocompra_data
  },
  Vget_pedidocompra_search: (state) => {
    return state.pedidocompra_search
  },
  Vget_pedidocompra_byId: (state) => {
    return state.pedidocompra_byId
  },
  Vget_pedidocompra_load: (state) => {
    return state.pedidocompra_load
  },
  Vget_pedidocompra_save_load: (state) => {
    return state.pedidocompra_save_load
  },
}
