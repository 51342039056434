const money = {
    Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    },

    Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + this.Unidades(unidad);
                }
                case 2:
                    switch (unidad) {
                        case 0:
                            return 'VEINTE';
                        default:
                            return 'VEINTI' + this.Unidades(unidad);
                    }
                    case 3:
                        return this.DecenasY('TREINTA', unidad);
                    case 4:
                        return this.DecenasY('CUARENTA', unidad);
                    case 5:
                        return this.DecenasY('CINCUENTA', unidad);
                    case 6:
                        return this.DecenasY('SESENTA', unidad);
                    case 7:
                        return this.DecenasY('SETENTA', unidad);
                    case 8:
                        return this.DecenasY('OCHENTA', unidad);
                    case 9:
                        return this.DecenasY('NOVENTA', unidad);
                    case 0:
                        return this.Unidades(unidad);
        }
    }, 

    DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + this.Unidades(numUnidades)

        return strSin;
    }, 

    Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + this.Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + this.Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + this.Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + this.Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + this.Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + this.Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + this.Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + this.Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + this.Decenas(decenas);
        }

        return this.Decenas(decenas);
    }, 

    Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = this.Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    }, 

    Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = this.Seccion(num, divisor, 'UN MIL', 'MIL');
        let strCentenas = this.Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    }, 

    Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = this.Seccion(num, divisor, this.millon(num, true), this.millon(num, false));
        let strMiles = this.Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    },
    millon(num, singular) {
        var letraMillon = '';
        if (singular == true)
            letraMillon = 'UN MILLON';
        else
            letraMillon = 'MILLONES'
        if (num % 1000000 == 0)
            letraMillon = letraMillon + ''
        return letraMillon;
    },
    MilMillones(num) {
        var divisor = 1000000000;
        var cientos = Math.floor(num / divisor);
        var resto = num - (cientos * divisor);

        var strMilMillones = this.Seccion(num, divisor, 'UN MIL', 'MIL');
        if (cientos > 0 && resto === 0)
            strMilMillones += ' MILLONES';
        var strMillones = this.Millones(resto);

        if (strMilMillones === '')
            return strMillones;

        return strMilMillones + ' ' + strMillones;
    }, 

    Billones(num) {
        var divisor = 1000000000000;
        var cientos = Math.floor(num / divisor);
        var resto = num - (cientos * divisor);

        var strBillones = this.Seccion(num, divisor, 'UN BILLON', 'BILLONES');
        var strMilMillones = this.MilMillones(resto);

        if (strBillones === '')
            return strMilMillones;

        return strBillones + ' ' + strMilMillones;
    },

    letter(num, currency) {
        currency = currency || {};
        let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || '',
            letrasMonedaSingular: currency.singular || '',
            letrasMonedaCentavoPlural: currency.centplural || '',
            letrasMonedaCentavoSingular: currency.centsingular || ''
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'CON ' + (() => {
                
                if (data.centavos == 1)
                    if (data.letrasMonedaCentavoSingular != '')
                        return this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                else
                    if (data.letrasMonedaCentavoPlural != '')
                       return this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
                return this.Millones(data.centavos)
            })();
        }

        if (data.enteros == 0) {
            if (data.letrasMonedaPlural != '')
                return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
            return 'CERO' + data.letrasCentavos;
        }
        if (data.enteros == 1)
            if (data.letrasMonedaSingular)
                return this.Billones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
            else
                if (data.letrasCentavos == 0)
                    return this.Billones(data.enteros)
                else
                    return this.Billones(data.enteros) + data.letrasCentavos
        else
            if (data.letrasMonedaPlural)
                return this.Billones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
            else
                if (data.letrasCentavos == 0)
                    return this.Billones(data.enteros)
                else
                    return this.Billones(data.enteros) + data.letrasCentavos

    }

}
export {
    money
}
// Ya definimos el cÃ³digo de la funciÃ³n, ahora sÃ­ a la lÃ³gica de la app
/*
const inputNumero = document.querySelector("#inputNumero"),
    botonConvertir = document.querySelector("#botonConvertir"),
    salida = document.querySelector("#salida");


// Escuchar el click del botÃ³n

botonConvertir.addEventListener("click",  () {
    // Obtener valor que hay en el input
    let valor = parseFloat(inputNumero.value);
    // Simple validaciÃ³n
    if (!valor) return alert("Escribe un valor");

    // Obtener la representaciÃ³n
   /*
    let letras = numeroALetras(valor, {
        plural: "DOLARES",
        singular: "DOLAR",
        centPlural: "CENTAVO",
        centSingular: "CENTAVOS"
    });
    
    let letras = numeroALetras(valor);
    // Y a la salida ponerle el resultado
    salida.innerText = letras;

}); 
*/