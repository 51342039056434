<template>
  <div>
    <div v-if="Vget_create">
      <Personas-Contacto-Create />
    </div>
    <v-autocomplete :value="value" :id="id" @input="$emit('input', $event)" :rules="rules_active"
      :search-input.sync="searchText" dense type="text" :messages="messages" :items="Vget_data" :label="label"
      :filter="customFilter" return-object item-text="desccontacto" outlined :readonly="readonly" :disabled="disabled"
      height="12" autocomplete="off" :loading="Vget_load" :no-data-text="`No existe ninguna concidencia`"
      v-on:change="change">
      <template slot="selection" slot-scope="data">
        <v-list-item-title>
          {{ data.item.desccontacto }} - RUC: {{ data.item.ruccontacto }}-{{ data.item.docucontactos }}
        </v-list-item-title>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.desccontacto }}</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.ruccontacto }}-{{ data.item.docucontactos }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="noData && isPermit" v-slot:no-data>
        <v-list-item @click="open_ref()">
          <v-list-item-content>
            <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import store from "@/store";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    label: String,
    value: [Number, String, Object],
    action: String,
    getter: String,
    load: String,
    vuex: String,
    id: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    permission: {
      type: String,
      default: null
    },
    rules: {
      type: Boolean,
      default: true
    },
    messages: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      dialog: false,
      validar: [v => !!v || "Seleccion un campo"],
      items: [],
      noData: true,
      searchText: null,
      isPermit: false
    };
  },

  mounted() {
    this.isPermit = getPermission(this.permission).permiso[PUEDE_AGREGAR];
    this.get_data();
  },
  watch: {
    searchText(val) {
      if (val != null) {
        if (val.length > 3) {
          store.dispatch(this.action, val);
        }
      }
    }
  },
  computed: {
    Vget_data() {
      return store.getters[this.getter];
    },
    Vget_create() {
      return store.getters["contacto/contacto_create"];
    },
    Vget_load() {
      return store.getters[this.load];
    },
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    }
  },
  methods: {
    async get_data() {
      store.dispatch(this.action);
    },
    open_ref() {
      this.$store.commit(this.vuex, true);
    },
    change(evt) {
      this.$emit("change", evt);
    },
    customFilter(item, queryText) {
      const textOne = item.ruccontacto.toLowerCase();
      const textTwo = item.desccontacto.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    }
  }
};
</script>
<style>
.v-select__selections {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  flex-wrap: nowrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
}
</style>