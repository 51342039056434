import { get, post, put, del } from "@/services/api/api.service";
export default {
    async Vact_motivotraslado_data({ commit }) {
        commit('motivotraslado_load', true)
        try {
            const data = await get('/apimotivotraslado/')
            commit('motivotraslado_data', data)
            commit('motivotraslado_load', false)
        } catch (e) {
            commit('motivotraslado_load', false)
            return e
        }
    },
    async Vact_motivotraslado_save({ commit, dispatch }, data) {
        commit('motivotraslado_load', true)
        try {
            const resp = await post('/apimotivotraslado/', data)
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('motivotraslado_load', false)
            return resp
        } catch (e) {
            commit('motivotraslado_load', false)
            return e
        }
    },
    async Vact_motivotraslado_update({ commit, dispatch }, { id, data }){
        commit('motivotraslado_load',true);
        try {
            const resp = await put('/apimotivotraslado/'+id+'/', data);
            dispatch('snackbar/setNotification', resp, { root: true })
            commit('motivotraslado_load', false);
            return resp;
        } catch (e) {
            commit('motivotraslado_load', false);
            return e
        } 
    },
    async Vact_motivotraslado_delete({ dispatch }, id){
        try {
            const data = await del('/apimotivotraslado/' + id + '/');
            dispatch('snackbar/setNotification', data, { root: true })
            return data
        } catch (e) {
            return false
        } 
    },
}
