<template>
  <div>
    <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
    <Crud v-if="crud.view" v-model="crud.view" :props="crud.datos" editable @datos="crud.datos = $event" />

    <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <c-text-table-search v-if="permission.can_view" v-model="search" class="mr-1"></c-text-table-search>

      <v-toolbar-items>
        <c-btn-table-add @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <v-form ref="form" v-if="!permission_alert">
      <v-toolbar flat>
        <c-text-date v-model="filtro.desde" label="Desde" class="mr-2"></c-text-date>
        <c-text-date v-model="filtro.hasta" label="Hasta" class="mr-2"></c-text-date>
        <autocomplete-sector v-model="filtro.sector" create label="Sector" class="mr-2" :return-object="false"
          :rules=[] />
        <v-btn text outlined color="deep-purple accent-4" @click="obtenerAsistencias()">Filtrar</v-btn>
        <v-spacer></v-spacer>
        <v-btn text outlined color="red darken-1 accent-4" @click="exportarAsistencias()">Exportar PDF</v-btn>
      </v-toolbar>
    </v-form>
    <v-data-table :headers="headers" :items="getAsistencia" :loading="isAsistenciaLoading" :search="search"
      v-if="permission.can_view">
      <template v-slot:[`item.idcontacto.nombre`]="props">
        <div>
          {{ props.item.idcontacto.nombre }}
          {{ props.item.idcontacto.apellido }}
        </div>
      </template>
      <template v-slot:[`item.idcontacto.asistencia`]="props">
        <div class="font-weight-bold">
          {{ toHourMinute(props.item.idcontacto.asistencia) }}
        </div>
      </template>
      <template v-slot:[`item.idcontacto.normal`]="props">
        <div class="font-weight-bold">
          {{ toHourMinute(props.item.idcontacto.normal) }}
        </div>
      </template>
      <template v-slot:[`item.idcontacto.extra`]="props">
        <div class="font-weight-bold">
          {{ toHourMinute(props.item.idcontacto.extra) }}
        </div>
      </template>
      <template v-slot:[`item.status`]="props">
        <v-chip color="green accent-3" dark
          v-if="props.item.idcontacto.consistencia.length === 0"><v-icon>check</v-icon></v-chip>
        <v-chip class="ma-2" color="amber" dark v-else>
          <v-avatar left class="amber lighten-3">
            <v-icon>error_outline</v-icon>
          </v-avatar>
          Con Problemas
        </v-chip>
      </template>
      <template v-slot:[`item.accion`]="props">
        <v-btn text fab outlined x-small color="deep-purple accent-4" @click="toRoute(props.item)">
          <v-icon> arrow_forward </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AutocompleteSector from "@/views/RRHH/Mantenimiento/Sector/autocomplete";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import Crud from "./Crud";
import { ASISTENCIA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import { exportTo } from "@/services/export.service";
export default {
  components: {
    Crud,
    "autocomplete-sector": AutocompleteSector,
  },
  data() {
    return {
      title: ASISTENCIA,
      search: "",
      filtro: {
        desde: current_first_date(),
        hasta: current_last_date(),
        sector: null,
      },
      headers: [
        {
          text: "Codigo",
          align: "left",
          value: "idcontacto.idcontacto_reloj",
        },
        {
          text: "Funcionario",
          align: "left",
          value: "idcontacto.nombre"
        },
        {
          text: "Dias Trabajados",
          align: "left",
          value: "idcontacto.diatrabajo"
        },
        {
          text: "Total Trabajado",
          align: "center",
          value: "idcontacto.asistencia",
        },
        {
          text: "Horas Normal",
          align: "center",
          value: "idcontacto.normal",
        },
        {
          text: "Horas Extra",
          align: "center",
          value: "idcontacto.extra",
        },
        {
          text: "Estado",
          align: "center",
          value: "status"
        },
        {
          text: "Accion",
          value: "accion",
          sortable: false,
          align: "end"
        },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
      },
      crud: {
        add: false,
        view: false,
        datos: null,
      },
    };
  },
  computed: {
    ...mapGetters("asistencia", ["getAsistencia", "isAsistenciaLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(ASISTENCIA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
    };
  },
  mounted() {
    setTimeout(() => this.obtenerAsistencias(), 30);
  },
  watch: {
    $route(to) {
      if (to['name'] == 'Asistencias') {
        this.obtenerAsistencias()
      }
    }
  },
  methods: {
    ...mapActions("asistencia", ["fetchAsistencia"]),
    toRoute(item) {
      this.$router.push({
        path: `/rrhh/asistencias/${item.idcontacto.idcontacto.idcontacto}/`,
        query: {
          name: `${item.idcontacto.nombre} ${item.idcontacto.apellido}`,
          desde: this.filtro.desde,
          hasta: this.filtro.hasta,
        },
      });
    },
    toHourMinute(val) {
      const temp = val * 60;
      const hours = Math.floor(temp / 60);
      const min = Math.floor(temp % 60);
      return `${hours}h ${min}m`;
    },
    obtenerAsistencias() {
      if (this.$refs.form) {
        if (!this.$refs.form.validate()) return null;
        const desde = this.filtro.desde;
        const hasta = this.filtro.hasta;
        let sector = null
        if (this.filtro.sector) sector = this.filtro.sector;
        this.fetchAsistencia({ desde, hasta, sector });
      }
    },
    exportarAsistencias() {
      let head = [
        [
          "Codigo",
          "Funcionario",
          "Total Trabajado",
          "Horas Normal",
          "Horas Extra",
        ],
      ];
      let body = [];
      this.getAsistencia.map((x) => {
        body.push([
          x.idcontacto.idcontacto_reloj,
          x.idcontacto.nombre + " " + x.idcontacto.apellido,
          this.toHourMinute(x.idcontacto.asistencia),
          this.toHourMinute(x.idcontacto.normal),
          this.toHourMinute(x.idcontacto.extra),
        ]);
      });
      exportTo.pdftable(head, body, "Asistencias");
    },
  },
};
</script>
