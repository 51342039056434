<template>
    <v-dialog v-model="value" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar flat color="primary">
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Configuracion de Archivo</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid>
                <v-alert type="info">Info! El sistema requiere un archivo solo con la estructura, no los datos,
                    para que el sistema cargue automaticamente los pagos</v-alert>

                <v-form ref="form" v-model="valid" lazy-validation>

                    <v-row>
                        <v-col cols="12">
                            <template>
                                <v-file-input accept=".xlsx" label="Cargue el archivos excel modelo"
                                    @change="handleFileUpload"></v-file-input>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <div v-html="tableHtml" @click="handleCellClick" id="excel"></div>
                        </v-col>
                        <v-col cols="4">
                            <v-card>
                                <v-card-title>Operaciones</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete v-model="data.operacion" :items="['Una Celda', 'Detalle']"
                                                label="Operacion" @change="operationChange()"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="data.celda" label="Celda"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-autocomplete v-if="data.operacion != 'Detalle'" v-model="data.dato"
                                                :items="['Fecha del Dia']" label="Dato"></v-autocomplete>
                                            <v-autocomplete v-else v-model="data.dato"
                                                :items="['Tipo', 'Monto', 'Funcionario Nombre', 'Funcionario Doc', 'Observacion']"
                                                multiple label="Dato"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" v-if="data.operacion == 'Detalle'">
                                            <draggable class="row ml-1" v-model="data.dato">
                                                <template>
                                                    <v-col cols="12" class="mr-n1" v-for="(item, i) of data.dato"
                                                        :key="i">
                                                        <v-card elevation="2" min-width="100" color="info">
                                                            <div class="d-flex mt-3 mb-2 mx-2">
                                                                <div class="subtitle-1 font-weight-medium">
                                                                    {{ item }}
                                                                </div>
                                                                <v-spacer></v-spacer>
                                                                <span class="font-weight-thin mr-1">Orden </span>
                                                                <div class="font-weight-bolt">{{ i }}</div>
                                                            </div>
                                                        </v-card>
                                                    </v-col>
                                                </template>
                                            </draggable>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="data.aplicar" label="Aplicar"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn color="primary" @click="agregarDetalle()">Agregar</v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-data-table :headers="headers" :items="operaciones">
                                                <template v-slot:item.accion="{ item }">
                                                    <v-btn icon
                                                        @click="operaciones.splice(operaciones.indexOf(item), 1)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn color="primary" @click="guardar()">Guardar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                </v-form>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import * as XLSX from "xlsx";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        draggable,
    },
    computed: {
        ...mapGetters("salario", ["getArchivoPagoConfig", "isArchivoPagoLoadingConfig"]),
    },
    methods: {
        ...mapActions("salario", ["setArchivoPagoConfig", "fetchArchivoPagoConfig"]),
        closeDialog() {
            this.$emit("input", false);
        },
        operationChange() {
            this.data.dato = null
        },
        handleFileUpload(event) {
            const file = event;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    //const html = XLSX.utils.sheet_to_html(worksheet);
                    //this.tableHtml = html;
                    this.tableHtml = this.generateHtmlTable(worksheet);
                };
                reader.readAsArrayBuffer(file);
                this.tosend.documento = file;
            }
        },
        generateHtmlTable(worksheet) {
            const range = XLSX.utils.decode_range(worksheet['!ref']);
            let html = '<table class="excel-table"><thead class="fixed-header"><tr><th></th>';
            for (let C = range.s.c; C <= range.e.c; ++C) {
                html += `<th class="excel-table">${XLSX.utils.encode_col(C)}</th>`;
            }
            html += '</tr></thead><tbody>';

            const mergeCells = worksheet['!merges'] || [];
            const mergeMap = {};

            mergeCells.forEach((merge) => {
                for (let R = merge.s.r; R <= merge.e.r; ++R) {
                    for (let C = merge.s.c; C <= merge.e.c; ++C) {
                        mergeMap[`${R},${C}`] = merge;
                    }
                }
            });

            for (let R = range.s.r; R <= range.e.r; ++R) {
                html += `<tr><th class="excel-table">${R + 1}</th>`;
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cellAddress = { c: C, r: R };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];
                    const cellValue = cell ? cell.v : '';

                    if (mergeMap[`${R},${C}`]) {
                        const merge = mergeMap[`${R},${C}`];
                        if (merge.s.r === R && merge.s.c === C) {
                            const rowspan = merge.e.r - merge.s.r + 1;
                            const colspan = merge.e.c - merge.s.c + 1;
                            html += `<td class="excel-table" rowspan="${rowspan}" colspan="${colspan}" data-cell-ref="${cellRef}">${cellValue}</td>`;
                        }
                    } else if (!mergeMap[`${R},${C}`] || (mergeMap[`${R},${C}`] && mergeMap[`${R},${C}`].s.r === R && mergeMap[`${R},${C}`].s.c === C)) {
                        html += `<td class="excel-table" data-cell-ref="${cellRef}">${cellValue}</td>`;
                    }
                }
                html += '</tr>';
            }
            html += '</tbody></table>';
            return html;
        },
        handleCellClick(event) {
            const cellRef = event.target.getAttribute('data-cell-ref');
            if (cellRef) {
                if (this.selectedCell) {
                    this.selectedCell.classList.remove('selected-cell');
                }
                event.target.classList.add('selected-cell');
                this.selectedCell = event.target;
                if (this.data.operacion === 'Una Celda') {
                    this.data.celda = cellRef;
                } else {
                    if (this.data.celda) {
                        this.data.celda = `${this.data.celda},${cellRef}`;
                    } else {
                        this.data.celda = cellRef;
                    }
                }
            }
        },
        async guardar() {
            await this.setArchivoPagoConfig(this.getFormData())
        },
        getFormData() {
            let Form = new FormData()
            Form.append('idarchivo_pago', this.tosend.idarchivo_pago)
            Form.append('documento', this.tosend.documento)
            Form.append('configuracion', JSON.stringify(this.operaciones))
            return Form
        },
        agregarDetalle() {
            this.operaciones.push(JSON.parse(JSON.stringify(this.data)))
        },
    },
    data() {
        return {
            archivo: null,
            valid: true,
            tableHtml: '',
            operaciones: [],
            headers: [
                { text: 'Operacion', value: 'operacion' },
                { text: 'Celda', value: 'celda' },
                { text: 'Dato', value: 'dato' },
                { text: 'Aplicar', value: 'aplicar' },
                { text: 'Accion', value: 'accion' },
            ],
            data: {
                operacion: null,
                celda: null,
                dato: null,
                aplicar: null,
            },
            tosend: {
                idarchivo_pago: 0,
                documento: null,
                configuracion: null,
            }
        };
    }
}
</script>
<style>
#excel {
    overflow: auto;
    height: 70vh;
}

.excel-table {
    border: 1px solid black;
    border-collapse: collapse;
    min-width: 30px;
}

.selected-cell {
    border: 2px solid #2c2f89;
}

.fixed-header th {
    position: sticky;
    top: 0;
    background: #46892c;
    z-index: 1;
}
</style>