<template>
  <div>
    <v-btn height="39" small text color="red" @click="obtenerDatos()">
      <v-icon>get_app</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" width="1200" persistent
      transition="dialog-bottom-transition">
      <v-card rounded>
        <v-toolbar flat dense>
          <v-toolbar-title>Lista de Actividades</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="d-flex justify-center">
          <h4>Cotizacion de Referencia: {{ info.cotizacion }}</h4>
        </div>
        <v-divider></v-divider>
        <v-container style="max-height: 75vh" class="overflow-y-auto">
          <c-overlay absolute :value="isLoading"></c-overlay>
          <v-data-table 
            :headers="permiso.can_view_numbers ? headers.aplicacion: headers.aplicacionNoP" 
            :items="data" 
            dense 
            hide-default-footer 
            :items-per-page="1000000"
            @click:row="clickRow" 
            show-expand 
            :expanded.sync="expanded2">
            <template v-slot:[`item.cantidad`]="{ item }">
              <div>{{ toCurrency(item.cantidad) }}</div>
            </template>
            <template v-slot:[`item.hectarea`]="{ item }">
              <div>{{ toCurrency(item.hectarea) }}</div>
            </template>
            <template v-slot:[`item.cantotal`]="{ item }">
              <div>{{ toCurrency(item.cantotal) }}</div>
            </template>
            <template v-slot:[`item.precio`]="{ item }">
              <div>{{ toCurrency(item.precio) }}</div>
            </template>
            <template v-slot:[`item.ph`]="{ item }">
              <div class="font-weight-black">{{ toCurrency(item.ph) }}</div>
            </template>
            <template v-slot:[`item.subtotal`]="{ item }">
              <div class="font-weight-black">
                {{ toCurrency(item.subtotal) }}
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                Propiedad Quimica: {{ item.descripcion }}
              </td>
            </template>
          </v-data-table>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="descargarPdf()">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
import { currency } from "@/services/util/number.service";
import { exportAplicaciones } from "./Export";
export default {
  props: {
    idcampana: {
      type: [Number, String],
    },
    idlote: {
      type: String,
    },
    permiso: {
      type: Object,
    }
  },
  data() {
    return {
      expanded2: [],
      isLoading: false,
      data: [],
      dialog: false,
      info: {
        fecha: "",
        lote: "",
        grupo: "",
        cotizacion: "",
      },
      headers: {
        aplicacion: [
          { text: "Fecha", value: "fecha", divider: true },
          {
            text: "Operacion",
            align: "left",
            value: "operacion",
            divider: true,
          },
          {
            text: "Descripcion",
            align: "left",
            value: "descitem",
            divider: true,
          },
          {
            text: "Categoria",
            align: "left",
            value: "categoria",
            divider: true,
          },

          {
            text: "Cantidad",
            align: "right",
            value: "cantidad",
            divider: true,
          },
          {
            text: "Hectarea",
            align: "right",
            value: "hectarea",
            divider: true,
          },
          {
            text: "Cant. Total",
            align: "start",
            value: "cantotal",
            divider: true,
          },
          { text: "Precio", align: "right", value: "precio", divider: true },
          { text: "Precio x Ha", align: "end", value: "ph", divider: true },
          {
            text: "Unidad Medida",
            align: "right",
            value: "unidad_medida",
            divider: true,
          },

          { text: "SubTotal", align: "end", value: "subtotal", divider: true },
          {
            text: "",
            value: "data-table-expand",
            align: "left",
          },
        ],
        aplicacionNoP: [
          { text: "Fecha", value: "fecha", divider: true },
          {
            text: "Operacion",
            align: "left",
            value: "operacion",
            divider: true,
          },
          {
            text: "Descripcion",
            align: "left",
            value: "descitem",
            divider: true,
          },
          {
            text: "Categoria",
            align: "left",
            value: "categoria",
            divider: true,
          },

          {
            text: "Cantidad",
            align: "right",
            value: "cantidad",
            divider: true,
          },
          {
            text: "Hectarea",
            align: "right",
            value: "hectarea",
            divider: true,
          },
          {
            text: "Cant. Total",
            align: "start",
            value: "cantotal",
            divider: true,
          },
          {
            text: "Unidad Medida",
            align: "right",
            value: "unidad_medida",
            divider: true,
          },
          {
            text: "",
            value: "data-table-expand",
            align: "left",
          },
        ],
      },
    };
  },
  methods: {
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded2.findIndex((i) => i === item);
        this.expanded2.splice(index, 1);
      } else {
        this.expanded2.push(item);
      }
    },
    toCurrency(value) {
      if (!value) return 0;
      if (value) return currency(value);
    },
    async obtenerDatos() {
      this.isLoading = true;
      try {
        this.dialog = true;
        const response = await db.get(
          `/apilistageneral/${this.idcampana}/${this.idlote}/`
        );
        this.data = JSON.parse(JSON.stringify(response.dato));
        this.info.fecha = response.fecha;
        this.info.cotizacion = response.cotizacion;
        this.info.lote = response.lote;
        this.info.grupo = response.grupo;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    cancelar() {
      this.dialog = false;
      this.data = [];
    },
    descargarPdf() {
      let data = {
        head: [],
        body: [],
      };
      data.head = [
        this.headers.aplicacion.map((x) => {
          return x.text;
        }),
      ];
      data.head[0].pop();
      (data.body = this.data.map((lista) => {
        return [
          lista.fecha,
          lista.operacion,
          lista.descripcion,
          lista.categoria,

          this.toCurrency(lista.cantidad),
          this.toCurrency(lista.hectarea),
          this.toCurrency(lista.cantotal),
          this.toCurrency(lista.precio),
          this.toCurrency(lista.ph),
          lista.unidad_medida,
          this.toCurrency(lista.subtotal),
        ];
      })),
        exportAplicaciones("Lista de Actividades", this.info, data);
    },
  },
};
</script>