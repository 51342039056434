
export default {
  Vget_notaremision_data: (state) => {
    return state.notaremision_data
  },
  Vget_notaremision_search: (state) => {
    return state.notaremision_search
  },
  Vget_notaremision_pendiente: (state) => {
    return state.notaremision_pendiente
  },
  Vget_notaremision_reporte: (state) => {
    return state.notaremision_reporte
  },
  Vget_notaremision_byId: (state) => {
    return state.notaremision_byId
  },
  Vget_notaremision_load: (state) => {
    return state.notaremision_load
  },
  Vget_notaremision_save_load: (state) => {
    return state.notaremision_save_load
  },
}
