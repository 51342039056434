<template>
  <div>
    <v-dialog :value="value" max-width="900px" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle de Translado Caja a Caja</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <c-text-date outlined dense label="Fecha Translado" readonly v-model="data.fechtransladocaja" />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field label="Sucursal Origen" :rules="false" v-model="data.idtamboorigen.nombtambo" readonly></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field label="Caja Origen" v-model="data.idcajaorigen.descaja" readonly></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Sucursal Destino"
                  v-model="data.idtambodestino.nombtambo"
                  readonly
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field label="Caja Destino" v-model="data.idcajadestino.descaja" readonly></vue-text-field>
              </v-col>
              <v-col cols="12">
                <vue-text-field readonly label="Motivo Traslado" v-model="data.motivotranslado" />
              </v-col>
            </v-row>
          </v-form>
          <v-row v-for="(item, i) of data.transladodet" :key="i" dense>
            <v-col cols="12" sm="2">
              <vue-text-field
                label="Moneda"
                readonly
                v-model="data.transladodet[i].idmoneda.descmoneda"
              ></vue-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <vue-text-currency label="Monto a Transferir" v-model="data.transladodet[i].monto"></vue-text-currency>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
              <vue-text-field
                label="Moneda"
                readonly
                v-model="data.transladodet[i].idmonedaconvertir.descmoneda"
              ></vue-text-field>
            </v-col>
            <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
              <vue-text-currency
                label="Cotizacion"
                v-model="data.transladodet[i].cotizacion"
                readonly
              ></vue-text-currency>
            </v-col>
            <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
              <vue-text-currency
                label="Monto a Recibir"
                readonly
                :value="data.transladodet[i].convertir * data.transladodet[i].monto"
              ></vue-text-currency>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    mostrar: [Object, Array],
  },
  data() {
    return {
      header: "Detalle",
      data: {
        idtransladocaja: 0,
        idcajaorigen: {
          idcaja: "",
          descaja: "",
        },
        idcajadestino: {
          idcaja: "",
          descaja: "",
        },
        idtamboorigen: {
          idtambo: "",
          nombtambo: "",
        },
        idtambodestino: {
          idtambo: "",
          nombtambo: "",
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: [],
        horatransladocaja: null,
      },
      default: {
        idtransladocaja: 0,
        idcajaorigen: {
          idcaja: "",
        },
        idcajadestino: {
          idcaja: "",
        },
        idtamboorigen: {
          idtambo: "",
        },
        idtambodestino: {
          idtambo: "",
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: [],
        horatransladocaja: null,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladocaja") return this.close();
    },
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.mostrar));
  },
  methods: {
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
    },
  },
};
</script>