<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Detalle de translado Banco a Banco'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <c-text-date label="Fecha" readonly v-model="data.fechtransladocaja" />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Numero de Operacion"
                  readonly
                  :rules="true"
                  v-model="data.nrotranslado"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <vue-text-currency label="Monto" readonly :rules="true" v-model="data.monto"></vue-text-currency>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Banco Origen"
                  readonly
                  :rules="true"
                  v-model="data.idbancoorigen.descbanco"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Cuenta de Origen"
                  readonly
                  :messages="monedaOrigen"
                  :rules="true"
                  v-model="data.idcuentabancoorigen.desccuentabanco"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Banco Destino"
                  readonly
                  :rules="true"
                  v-model="data.idbancodestino.descbanco"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Cuenta de Destino"
                  readonly
                  :messages="monedaDestino"
                  :rules="true"
                  v-model="data.idcuentabancodestino.desccuentabanco"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" v-if="data.convertir">
                <vue-text-currency
                  :label="'Cotizacion de ' + monedaDestino"
                  id="transBBInput8"
                  v-model="data.cotizacion"
                  @keyup.native.enter="next(8,data.cotizacion)"
                ></vue-text-currency>
              </v-col>
              <v-col>
                <vue-text-field
                  readonly
                  label="Motivo Traslado"
                  id="transBBInput8"
                  v-model="data.motivotranslado"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    mostrar: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      data: {
        idtransladobab: 0,
        idbancoorigen: {
          idbanco: "",
        },
        idbancodestino: {
          idbanco: "",
        },
        idcuentabancoorigen: {
          idcuentabanco: "",
        },
        idcuentabancodestino: {
          idcuentabanco: "",
        },
        fechtransladocaja: "",
        monto: "",
        motivotranslado: "",
        nrotranslado: "",
        estado: false,
      },
      default: {
        idtransladobab: 0,
        idbancoorigen: {
          idbanco: "",
        },
        idbancodestino: {
          idbanco: "",
        },
        idcuentabancoorigen: {
          idcuentabanco: "",
        },
        idcuentabancodestino: {
          idcuentabanco: "",
        },
        fechtransladocaja: "",
        monto: "",
        motivotranslado: "",
        nrotranslado: "",
        estado: false,
      },
    };
  },
  computed: {
    monedaOrigen() {
      return this.data.idcuentabancoorigen.idmoneda
        ? "Moneda: " + this.data.idcuentabancoorigen.idmoneda.descmoneda
        : "";
    },
    monedaDestino() {
      return this.data.idcuentabancodestino.idmoneda
        ? "Moneda: " + this.data.idcuentabancodestino.idmoneda.descmoneda
        : "";
    },
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.mostrar));
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladobancobanco") return this.cancelar();
    },
  },

  methods: {
    cancelar() {
      this.$emit("input", false);
      this.$emit("mostrar", null);
      this.data = JSON.parse(JSON.stringify(this.default));
    },
  },
};
</script>