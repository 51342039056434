<template>
  <div>
    <v-dialog :value="value" max-width="900px" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Agregar Translado Caja a Caja</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <c-text-date
                  label="Fecha Translado"
                  outlined
                  dense
                  id="transCCInput1"
                  v-model="data.fechtransladocaja"
                  @keyup.native.enter="next(1,data.fechtransladocaja)"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="Vget_tambo_data"
                  item-text="nombtambo"
                  item-value="idtambo"
                  id="transCCInput2"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[ v => !!v || 'Seleccione una Sucursal']"
                  :loading="Vget_tambo_load"
                  @change="getCajaOrigen()"
                  v-model="data.idtamboorigen.idtambo"
                  label="Sucursal Origen"
                  @keyup.enter="next(2,data.idtamboorigen.idtambo)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="caja_origen"
                  item-text="descaja"
                  return-object
                  id="transCCInput3"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[v => !!v.idcaja || 'Seleccione una caja']"
                  :loading="isLoading"
                  v-model="data.idcajaorigen"
                  @change="addDetalle()"
                  label="Caja Origen"
                  @keyup.enter="next(3,data.idcajaorigen.idcaja)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="Vget_tambo_data"
                  item-text="nombtambo"
                  item-value="idtambo"
                  id="transCCInput4"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[ v => !!v || 'Seleccione una Sucursal']"
                  :loading="Vget_tambo_load"
                  @change="getCajaDestino()"
                  v-model="data.idtambodestino.idtambo"
                  label="Sucursal Destino"
                  @keyup.enter="next(4,data.idtambodestino.idtambo)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  :items="caja_destino"
                  item-text="descaja"
                  item-value="idcaja"
                  id="transCCInput5"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="validar"
                  :loading="isLoading"
                  v-model="data.idcajadestino.idcaja"
                  label="Caja Destino"
                  @keyup.enter="next(5,data.idcajadestino.idcaja)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <vue-text-field
                  label="Motivo Traslado"
                  id="transCCInput6"
                  v-model="data.motivotranslado"
                  @keyup.native.enter="next(6,data.motivotranslado)"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formDetalle">
            <v-row v-for="(item, i) of data.transladodet" :key="i" dense>
              <v-col cols="12" sm="2">
                <vue-text-field
                  label="Moneda"
                  readonly
                  v-model="data.transladodet[i].idmoneda.descmoneda"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <vue-text-currency
                  :messages="data.transladodet[i].disponible"
                  label="Monto a Transferir"
                  :id="`transCCInput${7+i}`"
                  v-model="data.transladodet[i].monto"
                  @keyup.native.enter="next(7+i,'-')"
                ></vue-text-currency>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="2">
                <v-checkbox
                  v-model="data.transladodet[i].convertir"
                  @change="resetFila(i)"
                  label="Convertir"
                  class="ma-2"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                <v-autocomplete
                  :items="Vget_moneda_data"
                  item-text="descmoneda"
                  item-value="idmoneda"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[ v => !!v || 'Seleccione una Moneda']"
                  :loading="Vget_moneda_load"
                  v-model="data.transladodet[i].idmonedaconvertir.idmoneda"
                  label="Moneda"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                <vue-text-currency
                  label="Cotizacion"
                  :rules="true"
                  v-model="data.transladodet[i].cotizacion"
                  @keyup.native="calcularMontoRecibir(i)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                <vue-text-currency
                  label="Monto a Recibir"
                  :rules="true"
                  v-model="data.transladodet[i].montorecibir"
                  @keyup.native="calcularCotizacion(i)"
                ></vue-text-currency>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="transCCInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_transladocaja_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { SUCURSAL } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      caja_origen: [],
      caja_destino: [],
      isLoading: false,
      validar: [(v) => !!v || "Seleccione una Caja"],
      data: {
        idtransladocaja: 0,
        idcajaorigen: {
          idcaja: "",
        },
        idcajadestino: {
          idcaja: "",
        },
        idtamboorigen: {
          idtambo: "",
        },
        idtambodestino: {
          idtambo: "",
        },
        fechtransladocaja: current_date(),
        motivotranslado: "",
        transladodet: [],
        horatransladocaja: null,
      },
      default: {
        idtransladocaja: 0,
        idcajaorigen: {
          idcaja: "",
        },
        idcajadestino: {
          idcaja: "",
        },
        idtamboorigen: {
          idtambo: "",
        },
        idtambodestino: {
          idtambo: "",
        },
        fechtransladocaja: current_date(),
        motivotranslado: "",
        transladodet: [],
        horatransladocaja: null,
      },
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladocaja") return this.close();
    },
  },
  computed: {
    ...mapGetters("tambo", ["Vget_tambo_data", "Vget_tambo_load"]),
    ...mapGetters("caja", ["Vget_caja_data", "Vget_caja_load"]),
    ...mapGetters("transladocaja", ["Vget_transladocaja_save_load"]),
    ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),

    tambo_permission() {
      return getPermission(SUCURSAL).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.next(0, "-");
    this.Vact_tambo_data();
    this.Vact_moneda_data();
  },
  methods: {
    ...mapActions("tambo", ["Vact_tambo_data"]),
    ...mapActions("moneda", ["Vact_moneda_data"]),
    ...mapActions("transladocaja", [
      "Vact_transladocaja_save",
      "Vact_transladocaja_data",
    ]),
    resetFila(i) {
      if (!this.data.transladodet[i].convertir) {
        this.data.transladodet[i].idmonedaconvertir.idmoneda = "";
        this.data.transladodet[i].cotizacion = "";
        this.data.transladodet[i].montorecibir = "";
      }
    },
    calcularMontoRecibir(i) {
      if(this.data.transladodet[i].cotizacion === '' || this.data.transladodet[i].monto ==='' ) return null;
      this.data.transladodet[i].montorecibir =
        Number(this.data.transladodet[i].cotizacion) *
        Number(this.data.transladodet[i].monto);
    },
    calcularCotizacion(i) {
      if(this.data.transladodet[i].montorecibir === '' || this.data.transladodet[i].monto ==='' ) return null;
      this.data.transladodet[i].cotizacion =
        Number(this.data.transladodet[i].montorecibir) /
        Number(this.data.transladodet[i].monto);
    },
    addDetalle() {
      this.data.transladodet = [];
      this.data.idcajaorigen.cajadet.forEach((caja) => {
        this.data.transladodet.push({
          idtransladocaja: 0,
          idmoneda: caja.idmoneda,
          idmonedaconvertir: {
            idmoneda: "",
          },
          monto: "",
          disponible:
            "Saldo Disponible: " + new Intl.NumberFormat().format(caja.monto),
          estado: false,
          cotizacion: '',
          montorecibir: '',
          convertir: false,
        });
      });
    },
    next(index, data) {
      focus.nextidName("transCCInput", index, data);
    },
    async getCajaOrigen() {
      this.isLoading = true;
      try {
        const resp = await db.get(
          `/apicaja/?band=${this.data.idtamboorigen.idtambo}`
        );
        this.caja_origen = JSON.parse(JSON.stringify(resp));
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async getCajaDestino() {
      this.isLoading = true;
      try {
        const resp = await db.get(
          `/apicaja/?band=${this.data.idtambodestino.idtambo}`
        );
        this.caja_destino = JSON.parse(JSON.stringify(resp));
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
      this.$emit("input", false);
      this.Vact_transladocaja_data();
    },
    async guardar_finalizar() {
      let a = await this.Vact_transladocaja_save(this.data);
      if (!a.estado) {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => this.next(0,'-'), 200);
      return true;
    },
    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      if (this.operacion) return await this.guardar_finalizar();
    },
    isValid(){
      if (!this.$refs.form.validate() || !this.$refs.formDetalle.validate()) return false;
      return true;
    }
  },
};
</script>