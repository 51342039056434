<template>
    <div>
        <div v-if="crud.add">
            <Crud v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <div v-if="crud.view">
            <Crud readonly v-model="crud.view" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <v-card>
            <v-alert type="error" v-if="permission_alert">
                Error!!! Este usuario no tiene permisos en esta ventana
            </v-alert>
            <v-toolbar flat dense>
                <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                <widgets-Favorite ruta="/pedidocompra" formulario="Pedido Compra" />
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" :input.sync="search" append-icon="search" label="Busqueda" single-line
                    hide-details v-if="permission.can_view" class="mr-2"></v-text-field>
                <v-toolbar-items>
                    <c-btn-table-download class="error mr-2" @click="generarPdf()"
                        v-if="permission.can_view"></c-btn-table-download>
                    <c-btn-table-reload class="mr-2" @click="Vact_pedidocompra_data({ limit: 100, desc: null })"
                        v-if="permission.can_view"></c-btn-table-reload>
                    <c-btn-table-add @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table :loading="Vget_pedidocompra_load" :headers="headers" :items="Vget_pedidocompra_data"
                :search="search" class="elevation-1" v-if="permission.can_view">
                <template v-slot:item.accion="props">
                    <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
                    <c-btn-table-edit v-if="permission.can_update" @click="mupdate(props.item)"
                        :disabled="props.item.anulventa"></c-btn-table-edit>
                    <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"
                        :disabled="props.item.anulventa"></c-btn-table-delete>
                </template>
                <template v-slot:no-data>
                    <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
                </template>
            </v-data-table>
            <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
        </v-card>
    </div>
</template>

<script>
import Crud from "./Create";
import Delete from "./Delete";
import { COMPRA_PEDIDO } from "@/constants/forms";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
    components: {
        Crud,
        Delete,
    },
    data() {
        return {
            awaitingSearch: false,
            opcion: false,
            overlay: false,
            search: "",
            titulo: "Pedido Compra",
            crud: {
                add: false,
                view: false,
                delete: false,
                datos: null
            },
            abrircotizacion: false,
            headers: [
                { text: "Fecha", align: "left", value: "fechcomprapedido" },
                { text: "Vencimiento", align: "left", value: "fechavencimiento" },
                { text: "Estado", align: "left", value: "idestadopedido.descestadopedido" },
                { text: "Proveedor", align: "left", value: "idcontacto.desccontacto" },
                { text: "Nro Pedido", align: "right", value: "numpedido" },
                { text: "", align: "right", value: "accion", sortable: false },
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            }
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout")
        this.Vact_pedidocompra_data({ limit: 100, desc: null })
        const permission = getPermission(COMPRA_PEDIDO).permiso
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR]
        }
    },
    computed: {
        ...mapGetters("pedidocompra", ["Vget_pedidocompra_data", "Vget_pedidocompra_load"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    watch: {
        search() {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.busqueda()
                    this.awaitingSearch = false
                }, 1500)
            }
            this.awaitingSearch = true
        }
    },
    methods: {
        ...mapActions("pedidocompra", ["Vact_pedidocompra_data"]),
        async busqueda() {
            if (this.search.length >= 3 && this.search != null) {
                this.Vact_pedidocompra_data({ limit: null, desc: this.search });
            }
        },
        obtener(val) {
            console.log(val)
        },
        rowselect(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.view = true
        },
        meliminar(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.delete = true
        },
        mupdate(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val))
            this.crud.add = true
        }
    }
}
</script>