import ApiService from '../auth/api.service'
import { TokenService } from '../auth/storage.service'
import store from '@/store'

const getError = (error) => {
    let errors = []
    const keys = Object.keys(error);
    if(keys.length > 10) return ['Error desconocido']
    keys.forEach(key => {
        if (Array.isArray(error[key])) {
            errors.push(error[key][0]);
        } else {
            errors.push(error[key]);
        }
    })
    return errors;
}


export const post = async (url, data) => {
    const requestData = {
        method: 'POST',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }

    try {
        const response = await ApiService.customRequest(requestData);
        return {
            type: 'success',
            success: true,
            message: response.data.Success || 'Operacion exitosa!',
            data: response.data
        }
    } catch (error) {
        return {
            type: 'error',
            errors: getError(error.response.data),
            success: false,
            message: error.message
        }
    }
}

export const get = async (url) => {

    const requestData = {
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }
    }
    try {
        const response = await ApiService.customRequest(requestData)
        return response.data
    } catch (error) {
        console.log(error)
        throw {
            type: 'error',
            errors: getError(error.response.data),
            success: false,
            message: error.message
        }
    }
}
export const put = async (url, data) => {
    const requestData = {
        method: 'PUT',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }
    try {
        const response = await ApiService.customRequest(requestData);
        return {
            type: 'success',
            success: true,
            message: response.data.Success,
            data: response.data
        }
    } catch (error) {
        return {
            type: 'error',
            errors: getError(error.response.data),
            success: false,
            message: error.message
        }
    }

}
export const del = async (url, params) => {
    const requestData = {
        method: 'DELETE',
        url: url,
        params: params,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }

    try {
        const response = await ApiService.customRequest(requestData)
        return {
            type: 'success',
            success: true,
            message: response.data.Success
        }
    } catch (error) {
        return {
            type: 'error',
            success: false,
            errors: getError(error.response.data),
            message: error.message
        }
    }
}

export const saveform = async (url, data) => {
    const requestData = {
        method: 'POST',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`,
            'content-type': 'multipart/form-data'
        }

    }

    try {
        const response = await ApiService.customRequest(requestData)

        return {
            type: 'success',
            success: true,
            message: response.data.Success || 'Operacion exitosa!',
            data: response.data
        }


    } catch (error) {
        return {
            type: 'error',
            errors: getError(error.response.data),
            success: false,
            message: error.message
        }
    }
}

export const updateform = async (url, data) => {
    const requestData = {
        method: 'PATCH',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`,
            'content-type': 'multipart/form-data'
        }

    }

    try {
        const response = await ApiService.customRequest(requestData)
        return {
            type: 'success',
            success: true,
            message: response.data.Success
        }

    } catch (error) {
        return {
            type: 'error',
            errors: getError(error.response.data),
            success: false,
            message: error.message
        }
    }
}

export const trueGETBlob = async (url, fecha) => {
    console.log(fecha)
    let url2 = `${fecha.year}/${fecha.month}/${fecha.day}/`
    const requestData = {
        method: 'GET',
        responseType: 'blob',
        url: url + url2,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }
    }
    try {
        const response = await ApiService.customRequest(requestData)
        const blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pago.xlsx');
        document.body.appendChild(link);
        link.click();
        store.dispatch('conexion', true)
        return {
            info: {
                group: 'app',
                type: 'success',
                title: 'Mensaje:',
                text: response.data.Success
            },
            estado: true,
            data: response
        }
    } catch (error) {
        store.dispatch('conexion', false);
        let array = []
        Object.keys(error.response.data).map(key => {
            array.push(error.response.data[key][0]);
        });
        return {
            info: array,
            estado: false,
            data: error
        }
    }
}

export const getblob = async (url, data) => {
    const requestData = {
        method: 'POST',
        responseType: 'blob',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`,
        }

    }

    try {
        const response = await ApiService.customRequest(requestData)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Animal.pdf');
        document.body.appendChild(link);
        link.click();
        store.dispatch('conexion', true)

        return {
            info: {
                group: 'app',
                type: 'success',
                title: 'Mensaje:',
                text: response.data.Success
            },
            estado: true,
            data: response
        }


    } catch (error) {
        store.dispatch('conexion', false);
        let array = []
        Object.keys(error.response.data).map(key => {
            array.push(error.response.data[key][0]);
        });
        return {
            info: array,
            estado: false,
            data: error
        }
    }
}

export const  save_sync = async  (url, data) => {
    const requestData = {
        method: 'POST',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }
    }
    try {
        const response = await ApiService.customRequest(requestData)
        store.dispatch('conexion', true)
        return response.data
    } catch (error) {
        store.dispatch('conexion', false);
        throw new Error("Whoops!");
    }
}


